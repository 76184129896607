var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{style:({ width: '100%', height: '100vh' })},[_c('div',{staticClass:"alertContainer"},[(_vm.socialLoginFailed ? _vm.alertShowTime : false)?_c('frip-alert',{attrs:{"type":"warning","dismissible":true},on:{"dismissed":function () { return (_vm.socialLoginFailed = false); }},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('img',{attrs:{"src":require("@/assets/icons/warning-white.svg"),"width":"20"}})]},proxy:true}],null,false,2450789576)},[_c('span',{staticClass:"subTitleText",style:({ margin: '0 6px 0 6px' })},[_vm._v(" 소셜 계정 ")]),_vm._v("가입되지 않은 소셜 계정입니다. 가입을 진행해주세요! ")]):_vm._e(),_c('frip-alert',{attrs:{"show":_vm.emailLoginFailed ? _vm.alertShowTime : false,"type":"warning","dismissible":true},on:{"dismissed":function () { return (_vm.emailLoginFailed = false); }},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('img',{attrs:{"src":require("@/assets/icons/warning-white.svg"),"width":"20"}})]},proxy:true}])},[_vm._v(" 로그인에 실패하였습니다. 이메일, 비밀번호를 확인해주세요! ")])],1),_c('b-row',{staticClass:"justify-content-center w-100 no-gutters"},[_c('b-col',{staticClass:"d-none d-md-block no-gutters px-0",style:({
        overflow: 'hidden',
        position: 'relative',
        width: '100%',
        height: '100vh',
      }),attrs:{"cols":"6"}},[_c('img',{style:({ height: '100%' }),attrs:{"src":_vm.imageUrl}})]),_c('b-col',{staticClass:"px-0"},[_c('b-row',{staticClass:"justify-content-center no-gutters"},[_c('div',{staticClass:"loginBox"},[_c('validation-observer',{ref:"formValidator",scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var handleSubmit = ref.handleSubmit;
return [_c('b-form',{attrs:{"role":"form"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.login)}}},[_c('a',{staticClass:"benefitBanner",on:{"click":_vm.goHostJoinPage}},[_c('img',{style:({ height: '100px' }),attrs:{"src":require("@/assets/img/LoginPage/HostAdmin/benefitBanner.png"),"alt":"호스트 지원 바로가기 배너"}})]),_c('div',{staticClass:"titleText",style:({ marginTop: '54px' })},[_vm._v(" 로그인 ")]),_c('div',{staticClass:"subText1",style:({ marginTop: '16px' })},[_vm._v(" 환영합니다. "),_c('br'),_vm._v("로그인을 부탁드려요! ")]),_c('div',{style:({ marginTop: '42px' })},[_c('base-input',{attrs:{"name":"Email","rules":{ required: true, email: true },"placeholder":"아이디 (이메일)","invalid-message":"올바른 이메일을 입력해주세요."},nativeOn:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.pressEnterKey.apply(null, arguments)}},model:{value:(_vm.model.email),callback:function ($$v) {_vm.$set(_vm.model, "email", $$v)},expression:"model.email"}}),_c('div',{style:({ marginTop: '8px' })},[_c('base-input',{attrs:{"name":"Password","type":"password","placeholder":"비밀번호","required":"","invalid-message":"패스워드를 입력해주세요."},model:{value:(_vm.model.password),callback:function ($$v) {_vm.$set(_vm.model, "password", $$v)},expression:"model.password"}})],1)],1),_c('div',{style:({ marginTop: '12px' })},[_c('frip-button',{staticStyle:{"width":"100%"},attrs:{"native-type":"submit","loading":_vm.authStatus === 'loading'}},[_vm._v("로그인")])],1),_c('div',{style:({ marginTop: '16px', textAlign: 'center' })},[_c('router-link',{staticClass:"subText2",attrs:{"to":"/resetPassword"}},[_vm._v("비밀번호 찾기")]),_c('div',{staticClass:"verticalLine"}),_c('router-link',{staticClass:"subText2",attrs:{"to":"/regist"}},[_vm._v("호스트 가입")])],1),_c('div',{style:({
                  marginTop: '40px',
                  height: '1px',
                  background: '#EEEEEE',
                })}),_c('div',{style:({ marginTop: '16px' })},[_c('social-login-button',{attrs:{"provider":"KAKAO_SYNC"}}),_c('social-login-button',{attrs:{"provider":"FACEBOOK"}})],1)])]}}])}),_c('div',{staticClass:"subText3",style:({ marginTop: '16px' })},[_vm._v(" Apple 계정은 호스트 관리 페이지를 이용 할 수 없기에 다른 계정(이메일, 카카오, 페이스북) 방식으로 이용해주세요. ")])],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }