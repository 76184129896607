import { ModalOption } from '@/frip-alert-modal';
import { MessageType } from 'element-ui/types/message';
import Vue from 'vue';
import {
  parseApiError,
  parseValidationErrorToProductTab,
  VALIDATION_ERROR_PRODUCT_TAB_MAP,
} from '../ApiErrorParser';

export const ContainerMixin = Vue.extend({
  methods: {
    showAlert(alert: ModalOption) {
      this.$modal.show(alert);
    },
    showErrorAlert(error: any, title?: string) {
      const { message, errors } = parseApiError(error);
      const errorMessages: string[] = [];
      errors.forEach(error => {
        if (!errorMessages.includes(error.message)) {
          errorMessages.push(error.message);
        }
      });

      const errorMessage = errorMessages.map(i => `<li>${i}</li>`).join('');
      const ulStyle =
        'display: table; margin-left: auto; margin-right: auto; text-align: left';
      if (errors.length > 0) {
        this.$modal.show({
          title: title,
          html: `<ul style="${ulStyle}">${errorMessage}</ul>`,
          type: 'primary',
        });
        return;
      } else {
        this.$modal.show({
          title: title,
          html: message,
          type: 'primary',
        });
      }
    },

    showInspectionError(error: any, title?: string) {
      const { message, errors } = parseApiError(error);

      const errorTableRows = errors.map(
        error =>
          `<tr>
           <th style="background-color: #f4f4f4">${
             VALIDATION_ERROR_PRODUCT_TAB_MAP[error.fieldName]
           }</th>
           <td>${error.message}</td>
           </tr>`
      );
      if (errors.length > 0) {
        this.$modal.show({
          title: title,
          // HTML table tag td bug https://stackoverflow.com/questions/34483993/annoying-commas-at-beginning-of-html-table
          html: `
                <div>
                    <span>상품 등록에 오입력 또는 누락된 항목은 없는지 재확인해주세요.</span>
                    <table class="table table-bordered">
                    <tr>
                        <th style="background-color: #f4f4f4">입력항목</th>
                        <th style="background-color: #f4f4f4">내용</th>
                    </tr>
                    ${errorTableRows}
                    </table>
                </div>`.replace(/,/g, ''),
          type: 'primary',
        });
      } else {
        this.$modal.show({
          title: title,
          html: message,
          type: 'primary',
        });
      }

      return parseValidationErrorToProductTab(errors);
    },

    showSuccessMessage(title: string, message: string) {
      this.$notify({
        title: title,
        message: message,
        type: 'success',
      });
    },
    showErrorMessage(title: string, error: any) {
      this.$notify({
        title: title,
        message: error.message.replace('GraphQL error:', ''),
        type: 'warning',
      });
    },
    showMessage(title: string, message: string, type?: MessageType) {
      this.$notify({
        title: title,
        message: message,
        type: type || 'success',
      });
    },
  },
});
