



























































































































import Vue, { VueConstructor } from 'vue';
import draggable from 'vuedraggable';
import { ApolloError } from 'apollo-client';
import Spinner from '@/components/Spinner.vue';
import { ELEMENTS_SEARCHKEYWORDS_QUERY } from '../queries/query';
import { Table, TableColumn } from 'element-ui';
import { SearchKeyword } from '../model/searchKeyword';
import { SearchKeywordService } from '../service/SearchKeywordService';
import { apolloClient } from '@/apolloClient';

type Keyword = SearchKeyword & { isChanging: boolean };

const service = new SearchKeywordService(apolloClient);

export default (
  Vue as VueConstructor<Vue & { $refs: { [key: string]: HTMLElement } }>
).extend({
  name: 'SearchList',
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    Spinner,
    draggable,
  },
  data() {
    return {
      isAnyChanged: false,
      keywords: [] as Keyword[],
      showErrorMessageTime: 0,
      errorMessage: '',
      showSuccessMessageTime: 0,
      successMessage: '',
      loading: 0,
    };
  },
  computed: {
    isAnyChangedType(): string {
      return !this.isAnyChanged ? 'secondary' : 'primary';
    },
  },

  methods: {
    isAnyChanging(): boolean {
      return this.keywords.some(keyword => keyword.isChanging == true);
    },
    sortSeqNo(): void {
      this.keywords.forEach((keyword, idx) => (keyword.seqNo = idx + 1));
    },
    addKeyword(): void {
      this.keywords.push({
        id: '0',
        seqNo: this.keywords.length + 1,
        text: '',
        exposureText: '',
        isChanging: true,
      });
    },
    async saveKeywords(): Promise<void> {
      this.$modal.show(
        {
          title: '변경 하시겠습니까?',
          type: 'warning',
          showCancelButton: true,
        },
        async () => {
          const inputSearchKeyword = this.keywords.map(
            ({ id, seqNo, exposureText, text }) => {
              return {
                id: id === '0' ? undefined : id,
                seqNo: seqNo,
                text: text,
                exposureText: exposureText,
              };
            }
          );
          try {
            if (inputSearchKeyword.length > 20) {
              throw new Error('검색키워드의 최대 갯수는 20개 입니다.');
            }
            await service.saveSearchKeywords(inputSearchKeyword);
            this.$apollo.queries.uspsQuery.refetch();
            this.showSuccessMessage('적용 되었습니다.');
            this.isAnyChanged = false;
          } catch (err) {
            console.error(err);
            this.showErrorMessage(err.message);
          }
        }
      );
    },
    showErrorMessage(message: string): void {
      this.showErrorMessageTime = 5;
      this.errorMessage = message.replace('GraphQL error:', '');
    },
    countDownChanged(showErrorMessageTime: number) {
      this.showErrorMessageTime = showErrorMessageTime;
    },
    showSuccessMessage(message: string): void {
      this.showSuccessMessageTime = 5;
      this.successMessage = message;
    },
    countDownSuccessChanged(showSuccessMessageTime: number) {
      this.showSuccessMessageTime = showSuccessMessageTime;
    },
  },
  apollo: {
    uspsQuery: {
      query: ELEMENTS_SEARCHKEYWORDS_QUERY,
      error(error: ApolloError): void {
        console.error(error);
        this.showErrorMessage(error.message);
      },
      update(data: { elements: { searchKeywords: SearchKeyword[] } }): void {
        const resultSeachKeyword = data.elements.searchKeywords;
        this.keywords = [];
        for (const searchKeyword of resultSeachKeyword) {
          const keyword = Object.assign({ isChanging: false }, searchKeyword);
          this.keywords.push(keyword);
        }
      },
    },
  },
});
