






































































































































































































import BaseInput from '@/BaseComponents/Inputs/BaseInput.vue';
import FripButton from '@/components/FripComponents/FripButton.vue';
import Vue, { VueConstructor } from 'vue';
import { getAuthUrl } from '@/env';
import axios from 'axios';
import { GET_ONE_QUERY } from '@/domain/user/graphqls/user';
import FripAlert from '@/components/FripComponents/FripAlert.vue';
import { EmailUserRegistParam } from '@/domain/login/model/identity';
import { User } from '@/domain/user/models/user';
import { extend } from 'vee-validate';
import AwsKinesis from '@/lib/awsKinesis';
import RegisterFormAccountInfoCompleteEvent from '@/lib/awsKinesis/events/RegisterFormAccountInfoCompleteEvent';

export default (
  Vue as VueConstructor<Vue & { $refs: { [key: string]: HTMLElement } }>
).extend({
  components: { BaseInput, FripButton, FripAlert },
  data(): {
    password: string;
    passwordCheck: string;
    phoneNumber: string;
    email: string;
    newbie: boolean | null;
    emailChecked: boolean;
    isValidAccount: boolean;
    isValidPassword: boolean;

    alertShowTime: number;
    loading: { checkEmail: boolean; checkAccount: boolean };
  } {
    return {
      email: '',
      password: '',
      passwordCheck: '',
      phoneNumber: '',
      newbie: null,
      emailChecked: false,
      isValidAccount: false,
      isValidPassword: true,

      alertShowTime: 3,
      loading: { checkEmail: false, checkAccount: false },
    };
  },
  created() {
    extend('passwordCheck', password => {
      return password === this.password;
    });
  },

  methods: {
    moveToPreviousStep() {
      this.$emit('update', 'previous');
    },
    validateEmail(email: string): boolean {
      const emailRegex =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return emailRegex.test(String(email).toLowerCase());
    },
    validatePassword(password: string): boolean {
      const passwordRegExpList = [
        /[0-9]+/, // 숫자
        /[a-z]+/, // 소문자
        /[A-Z]+/, // 대문자
        /[$@^#!%*?&]+/, // 특수문자
      ];
      const resultList = passwordRegExpList.map(regExp =>
        regExp.test(password)
      );
      return (
        String(password).length >= 10 &&
        resultList.filter(result => result).length >= 2
      );
    },
    async checkEmail() {
      this.loading.checkEmail = true;
      if (this.emailChecked) {
        console.log('recheckEmail');
        this.newbie = null;
        this.emailChecked = false;
        this.isValidAccount = false;
      } else {
        console.log(getAuthUrl());
        try {
          const result = await axios({
            url: `${getAuthUrl()}/validation/email`,
            params: {
              email: this.email,
            },
            method: 'GET',
          });
          console.log('checkEmail:', result?.data?.data);
          this.newbie = true;
          this.$emit('alert', '프립 회원');
        } catch (e) {
          this.newbie = false;
          this.$emit('alert', '');

          console.log(e);
        } finally {
          this.emailChecked = true;
        }
      }
      this.loading.checkEmail = false;
    },
    async checkAccount() {
      console.log('clicked');
      this.loading.checkAccount = true;
      console.log(this.isValidPassword);
      try {
        const hostToken = await this.$store.dispatch('login', {
          email: this.email,
          password: this.password,
          byUser: false,
        });
        if (hostToken?.hostId) {
          this.$router.push('/').then(() => location.reload());
        }
      } catch (err) {
        console.log('hostLogin', err);
      }
      try {
        const userToken = await this.$store.dispatch('login', {
          email: this.email,
          password: this.password,
          byUser: true,
        });
        if (userToken?.userId) {
          const result = await this.$apollo.query({
            query: GET_ONE_QUERY,
            variables: {
              id: userToken.userId,
            },
          });
          console.log(result);
          const user: User = result.data.user;
          const param: EmailUserRegistParam = {
            email: user.email,
            password: this.password,
            userId: user.id,
            phoneNumber: user.mobileNumber,
            isNewbie: false,
          };
          this.$emit('emailUser', param);
          console.log(user);
          this.$emit('update', 'next');
        }
      } catch (err) {
        console.log('userLogin', err);
        this.isValidPassword = false;
      }
      this.loading.checkAccount = false;
    },
    async createAccount() {
      if (this.password !== this.passwordCheck) {
        this.isValidPassword = false;
        return;
      }
      if (!this.validatePassword(this.password)) {
        this.isValidPassword = false;
        return;
      }
      const param: EmailUserRegistParam = {
        email: this.email,
        password: this.password,
        isNewbie: true,
      };

      new AwsKinesis().pushRecord(new RegisterFormAccountInfoCompleteEvent());

      this.$emit('emailUser', param);
      this.$emit('update', 'next');
    },
  },
});
