

























































import Vue from 'vue';
import ImageUploadContainer from '@/components/ImageUpload/ImageUploadBox.vue';
import { Image } from '../model/banner';

export default Vue.extend({
  components: {
    ImageUploadContainer,
  },
  props: {
    imageInfo: {
      type: Object,
    },

    defaultImages: {
      type: Array,
    },
  },
  data() {
    return {
      uploadedImage: {
        aImage: '',
        bImage: '',
      },
      aTypeImage: {} as Image,
      bTypeImage: {} as Image,
    };
  },
  created(): void {
    const images: Image[] = this.defaultImages as Image[];
    // props에서 interface를 타입으로 설정할 수 없는 문제 때문에 이렇게 함
    if (this.defaultImages) {
      const aTypeImages: Image[] = images.filter(
        image => image.testType === 'A'
      );
      const bTypeImages: Image[] = images.filter(
        (images: Image) => images.testType === 'B'
      );

      this.uploadedImage.aImage =
        aTypeImages.length > 0 ? aTypeImages[0].contentId : '';
      this.uploadedImage.bImage =
        bTypeImages.length > 0 ? bTypeImages[0].contentId : '';

      this.aTypeImage = aTypeImages[0] || null;
      this.bTypeImage = bTypeImages[0] || null;
    }
  },

  methods: {
    sendContentId(): void {
      this.$emit('input', this.uploadedImage);
    },
  },
});
