

































































































































import Vue, { VueConstructor } from 'vue';
import { EmailUserRegistParam } from '@/domain/login/model/identity';
import SocialLoginButton from '@/domain/login/common/views/SocialLoginButton.vue';
import { HostLoginService } from '@/domain/login/host/service/HostLoginService';
import { apolloClient } from '@/apolloClient';
import AwsKinesis from '@/lib/awsKinesis';
import LoginViewEvent from '@/lib/awsKinesis/events/LoginViewEvent';

export default (
  Vue as VueConstructor<Vue & { $refs: { [key: string]: HTMLElement } }>
).extend({
  components: { SocialLoginButton },
  data(): {
    model: any;
    modalTitle: string;
    modalContent: string;
    loginModalStatus: boolean;
    socialLoginFailed: boolean;
    emailLoginFailed: boolean;
    alertShowTime: number;
    isHost: boolean;
  } {
    return {
      isHost: true,
      model: {
        email: '',
        password: '',
        rememberMe: false,
      },
      modalTitle: '',
      modalContent: '',
      loginModalStatus: false,
      socialLoginFailed: false,
      emailLoginFailed: false,
      alertShowTime: 3,
    };
  },
  computed: {
    authStatus() {
      return this.$store.state.authStatus;
    },
    imageUrl() {
      const index = Math.floor(Math.random() * 5) + 1;
      return require(`@/assets/img/LoginPage/HostAdmin/${index}.png`);
    },
  },
  mounted() {
    new AwsKinesis().pushRecord(new LoginViewEvent());
  },
  methods: {
    goHostJoinPage(): void {
      window.open('https://team.frip.co.kr/joinhost');
    },
    onSubmit() {
      console.log(this.model);
      // this will be called only after form is valid. You can do api call here to login
    },
    pressEnterKey() {
      // if (!this.validateAccountName()) return;
      this.$refs.password.focus();
    },
    async login() {
      const hostLoginService = new HostLoginService(apolloClient, this);

      console.log(this);

      let email = this.model.email;
      let password = this.model.password;
      const result = await hostLoginService.emailLogin(email, password);
      console.log(result);
      if (result.success) {
        this.$router.push('/').then(() => location.reload());
        return;
      }
      if (result.user) {
        this.isHost = false;
        const user = result.user;
        const param: EmailUserRegistParam = {
          email: user.email,
          password: this.model.password,
          userId: user.id,
          phoneNumber: user.mobileNumber,
          isNewbie: false,
        };
        //유저 정보를 회원가입 컴포넌트에 넘겨줌
        this.$router.push({
          name: 'registForm',
          params: {
            param: JSON.stringify(param),
          },
        });
      } else {
        this.emailLoginFailed = true;
      }
    },

    showModal(title: string, content: string) {
      this.modalTitle = title;
      this.modalContent = content;
      this.loginModalStatus = true;
    },
  },
});
