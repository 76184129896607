








































import Vue from 'vue';
import ServiceQnaStatusCard from '@/domain/qna/component/ServiceQnaStatusCard.vue';
import ServiceImageLayout from '@/domain/qna/component/ServiceImageLayout.vue';
import ServiceAnswerCard from '@/domain/qna/component/ServiceAnswerCard.vue';

export default Vue.extend({
  name: 'ServiceQnaCard',
  components: {
    ServiceImageLayout,
    ServiceQnaStatusCard,
    ServiceAnswerCard,
  },
  props: {
    qna: {
      type: Object,
    },
  },
  data() {
    return {
      qnaRow: {},
    };
  },
  created() {
    this.qnaRow = this.qna;
  },
  methods: {
    issueTypeColor(secret: boolean): string {
      if (secret) {
        return 'danger';
      } else {
        return 'success';
      }
    },
  },
});
