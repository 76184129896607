

























































































































































































































































































































































import Vue, { VueConstructor } from 'vue';
import { getHostIntroducerTypes } from '@/domain/host/graphqls/host';
import { HostParam } from '@/domain/host/models/host';
import { HostService } from '@/domain/host/service/hostService';
import { apolloClient } from '@/apolloClient';
import { imageUploadService } from '@/components/ImageUpload/imageUploadService';
import PhoneVerificationForm from '@/domain/login/common/views/PhoneVerificationForm.vue';
import {
  VerificationForm,
  SignUpParam,
  SocialSignUpParam,
  EmailUserByLogin,
  SocialUserByLogin,
} from '@/domain/login/model/identity';
import profile1 from '@/assets/img/profile/profile-1.png';
import profile2 from '@/assets/img/profile/profile-2.png';
import profile3 from '@/assets/img/profile/profile-3.png';
import AwsKinesis from '@/lib/awsKinesis';
import RegisterCompleteEvent from '@/lib/awsKinesis/events/RegisterCompleteEvent';

const hostService = new HostService(apolloClient);
export default (
  Vue as VueConstructor<Vue & { $refs: { [key: string]: any } }>
).extend({
  components: {
    PhoneVerificationForm,
  },
  props: {
    emailUserByLogin: EmailUserByLogin,
    socialUserByLogin: SocialUserByLogin,
  },
  data(): {
    isAccordionOpen: boolean;
    exampleModal: boolean;
    profileImage: string | ArrayBuffer | null;
    profileImageId: string;
    profileImagePath: string;
    isValidForm: boolean;
    introducerTypes: any;
    selectedIntroducerType: string;
    param: HostParam;
    profileImageFile: any;
    verificationKey: string;
    tooManyRequest: boolean;
    loading: boolean;
    isNewbie: boolean;
    tryToRegistHost: boolean;
    personalPhoneNumberToAuthorize: string;
  } {
    return {
      personalPhoneNumberToAuthorize: '',
      isAccordionOpen: false,
      isNewbie:
        this.$props?.emailUserByLogin?.isNewbie ||
        this.$props?.socialUserByLogin?.isNewbie,
      tooManyRequest: false,
      isValidForm: true,
      exampleModal: false,
      profileImage: null,
      profileImageId: '',
      profileImagePath: '',
      introducerTypes: [],
      selectedIntroducerType: '',
      verificationKey: '',
      param: {
        description: '',
        name: '',
        // realName: '',
        email:
          this?.emailUserByLogin?.email || this?.socialUserByLogin?.email || '',
        userId: '0',
        // certificationKey: '',
        phoneNumber: '',
        personalPhoneNumber:
          this?.emailUserByLogin?.phoneNumber ||
          this?.socialUserByLogin?.phoneNumber ||
          '',
        introducerTypeId: 'etc',
        etc: '',
        saleManaerName: '',
        refereeName: '',
        refereePhoneNumber: '',
        isEvent: true,
      },
      profileImageFile: null,
      loading: false,
      tryToRegistHost: false,
    };
  },
  computed: {
    isPhoneNumberAuthenticated(): boolean {
      return Boolean(this.param.personalPhoneNumber);
    },
  },
  apollo: {
    introducerTypes: {
      query: getHostIntroducerTypes,
      update(data): any[] {
        console.log(data);
        return data.hostIntroducerTypes;
      },
    },
  },
  mounted() {
    this.getRandomImage();
  },
  methods: {
    moveToPreviousStep() {
      this.$emit('update', 'previous');
    },
    async srcToFile(src: string, fileName: string, mimeType: string) {
      return fetch(src)
        .then(data => data.arrayBuffer())
        .then(buffer => new File([buffer], fileName, { type: mimeType }));
    },
    async getRandomImage() {
      const images: Array<{ name: string; image: any }> = [
        { name: 'profile-1.png', image: profile1 },
        { name: 'profile-2.png', image: profile2 },
        { name: 'profile-3.png', image: profile3 },
      ];
      const randomNum = Math.floor(Math.random() * 3);
      const { name, image } = (images &&
        images.find((_, index) => index === randomNum)) || {
        name: 'profile-1.png',
        image: profile1,
      };

      const file = await this.srcToFile(image, name, 'image/png');

      this.profileImageFile = file;
      this.profileImage = image;
    },
    async validateForm() {
      const isValid = await this.$refs.formValidator.validate();
      /**
       * 필수 항목이 채워졌는지 + 휴대폰 인증이 완료되었는지 체크한다.
       */
      this.isValidForm = isValid && this.isPhoneNumberAuthenticated;
    },
    async signUpEmailUser(param: HostParam, isNewbie: boolean) {
      if (isNewbie) {
        console.log(111111);
        console.log(this.$store.state);
        const param: SignUpParam = {
          email: this.emailUserByLogin.email,
          password: this.emailUserByLogin.password,
          nickname: '프립 크루',
          mobileNumber: this.param.personalPhoneNumber,
          verificationKey: this.verificationKey,
          agreement: { marketing: false },
          store: 'frip',
        };
        const userToken = await this.$store.dispatch('signUp', param);
        console.log('가입 성공');
        this.param.userId = String(userToken.userId);
      } else if (this.emailUserByLogin?.userId) {
        console.log(2222222);
        console.log('호스트만 가입', this.$store.state.userToken);
        this.param.userId = this.emailUserByLogin.userId;
      }
    },
    async signUpSocialUser(param: HostParam, isNewbie: boolean) {
      if (isNewbie) {
        console.log(333333);

        const param: SocialSignUpParam = {
          email: this.socialUserByLogin.email || '',
          token: this.socialUserByLogin.token,
          provider: this.socialUserByLogin.provider,
          nickname: this.socialUserByLogin.nickname || '프립 크루',
          mobileNumber: this.param.personalPhoneNumber,
          verificationKey: this.verificationKey,
          agreement: this.socialUserByLogin.agreement,
        };
        const userToken = await this.$store.dispatch('socialSignUp', param);
        this.param.userId = String(userToken.userId);
      } else if (this.socialUserByLogin?.userId) {
        console.log(444444);

        console.log('호스트만 가입', this.$store.state.userToken);
        this.param.userId = this.socialUserByLogin.userId;
      }
    },
    setVerificationForm(form: VerificationForm) {
      this.param.personalPhoneNumber = form.mobileNumber;
      this.verificationKey = form.verificationKey;
    },
    showExample() {
      this.exampleModal = true;
    },
    async handleUpload(e: any) {
      if (e.target.files[0]) {
        console.log('e.target.files[0]', e.target.files[0]);
        this.profileImagePath = e.target.files[0].name;
        const img = new FormData();
        img.append('file', e.target.files[0]);
        const reader = new FileReader();
        const file = e.target.files[0];
        reader.onload = () => {
          this.profileImage = reader.result;
        };
        reader.readAsDataURL(file);
        //서버에 업로드
        //권한이 없어서 안된다,,, 일단 유저를 생성해야하나?

        this.profileImageFile = file;
      }
    },
    async registHost() {
      if (!this.isValidForm) {
        return;
      }
      try {
        this.tryToRegistHost = true;
        this.loading = true;
        console.log(this.socialUserByLogin);
        if (this.emailUserByLogin) {
          await this.signUpEmailUser(
            this.param,
            this.emailUserByLogin.isNewbie
          );
        } else {
          await this.signUpSocialUser(
            this.param,
            this.socialUserByLogin.isNewbie
          );
        }

        if (!this.param.userId) {
          console.log(this.$store.state);
          throw Error('userId가 없습니다');
        }
        if (this.profileImageFile !== null) {
          const { contentId } = await imageUploadService.uploadImage(
            this.profileImageFile
          );
          this.profileImageId = contentId;
          console.log(this.profileImageId);
          const host = await hostService.signUp({
            ...this.param,
            phoneNumber:
              this.param.phoneNumber || this.param.personalPhoneNumber,
            profileContentId: this.profileImageId,
          });
          if (!host.id) {
            throw new Error('호스트 아이디가 생성되지 않음');
          }

          // kinesis tracking
          new AwsKinesis().pushRecord(new RegisterCompleteEvent(host.id));
        } else {
          throw new Error('프로필 이미지가 입력되지 않았습니다');
        }
        this.loading = false;

        if (this.emailUserByLogin) {
          this.$router.push({
            name: 'HostRegistCompleted',
            params: {
              param: JSON.stringify(this.emailUserByLogin),
            },
          });
        } else {
          this.$router.push({
            name: 'HostRegistCompleted',
            params: {
              param: JSON.stringify(this.socialUserByLogin),
            },
          });
        }
      } catch (err) {
        console.log(err);
      }
    },
  },
});
