






















import Vue from 'vue';
export default Vue.extend({
  name: 'ServiceImageLayout',
  components: {},
  props: {
    images: {
      type: Array,
    },
  },
  data() {
    return {
      serviceImages: [] as any[],
      mainProps: {
        center: true,
        width: 300,
        height: 300,
      },
      chunkSize: 3,
    };
  },
  computed: {
    chunkedImages(): { id: number; uri: string; thumbnail: string }[][] {
      const chuckImages = [];

      for (let i = 0; i < this.serviceImages.length; i += 3) {
        chuckImages.push(this.serviceImages.slice(i, i + 3));
      }

      return chuckImages;
    },
  },
  created() {
    this.serviceImages = this.images;
  },
  methods: {
    imagePopup(imageUrl: string): void {
      if (imageUrl !== '') {
        window.open(imageUrl, '_blank', 'width=500,height=600');
      }
    },
  },
});
