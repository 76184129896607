









































































import Vue from 'vue';
import { getAuthUrl, isHostAdmin } from '@/env';
import axios from 'axios';

export default Vue.extend({
  data(): { email: string; success: boolean; errorCode: string } {
    return {
      email: '',
      success: false,
      errorCode: '',
    };
  },
  methods: {
    async sendPasswordResetEmail() {
      console.log(this.email);
      console.log(getAuthUrl());
      try {
        const result = await axios({
          url: `${getAuthUrl()}/password/reset/request`,
          data: {
            email: this.email,
          },
          method: 'POST',
        });
        console.log('resetPasswordRequest:', result?.data?.data);
        this.success = true;
      } catch (e: any) {
        console.log(e.response);
        if (e.response.status === 404) {
          this.errorCode = '404';
        }
        throw e;
      }
    },
  },
});
