
















































































































import Vue from 'vue';
import {
  Badge,
  Card,
  Option,
  Pagination,
  Row,
  Select,
  Switch,
  Table,
  TableColumn,
  Tag,
} from 'element-ui';
import Spinner from '@/components/Spinner.vue';
import {
  QUESTION_TYPE_LIST,
  SERVICE_QNA_LIST_QUERY,
} from '@/domain/qna/queries/query';
import { ApolloError, ApolloQueryResult } from 'apollo-client';
import {
  ServiceQnaConnection,
  ServiceQnaEdge,
  ServiceQuestionType,
} from '@/domain/qna/model/serviceQna';
import ServiceQuestionCard from '@/domain/qna/component/ServiceQuestionCard.vue';
import { CommonState } from '@frientrip/domain';

export default Vue.extend({
  name: 'ServiceQnaList',
  components: {
    ServiceQuestionCard,
    [Badge.name]: Badge,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    Spinner,
    [Card.name]: Card,
    [Tag.name]: Tag,
    [Row.name]: Row,
    [Switch.name]: Switch,
    [Pagination.name]: Pagination,
    [Select.name]: Select,
    [Option.name]: Option,
  },
  data() {
    return {
      qnas: [],
      columns: ['question', 'reply'],
      loading: 1,
      filter: {
        answer: '',
        title: '',
        question: '',
        userId: this.$route.query.userId || '',
        typeIds: [],
        isAnswering: true,
      },
      totalCount: 0,
      showModal: false,
      page: this.$route.query.page || 1,
      pageSize: this.$route.query.size || 10,
      typeList: [],
      typeId: '',
      loadingSelect: true,
    };
  },
  methods: {
    getServiceQuestionList(): void {
      this.$apollo.queries.serviceQnas.refetch();
    },
    changeFilterStatus(event: boolean): void {
      this.$data.filter.statusIn = [CommonState.ACTIVE, CommonState.DELETED];

      if (!event) {
        this.$data.filter.statusIn = [CommonState.ACTIVE];
      }

      (this as any).getServiceQuestionList();
    },
    changeFilterForIsAnswering(evnet: boolean): void {
      this.$data.filter.isAnswering = !evnet;
      (this as any).getServiceQuestionList();
    },
  },
  apollo: {
    serviceQnas: {
      query: SERVICE_QNA_LIST_QUERY,
      variables() {
        return {
          filter: this.$data.filter,
          page: Number(this.$data.page),
          size: Number(this.$data.pageSize),
        };
      },
      error(e: ApolloError) {
        console.error(e);
      },
      result(
        result: ApolloQueryResult<{
          qna: { serviceQnas: ServiceQnaConnection };
        }>
      ) {
        this.$data.totalCount = result.data.qna.serviceQnas.totalCount;
        this.$data.qnas = result.data.qna.serviceQnas.edges.map(
          (edge: ServiceQnaEdge) => {
            if (!edge.node.answer) {
              edge.node.answer = {
                answer: '',
                createdAt: '',
              };
            }

            if (!edge.node.images) {
              edge.node.images = {} as { id: number };
            }

            return edge.node;
          }
        );

        this.$data.loading = 0;
      },
      update: data => data.qna.serviceQnas,
    },
    serviceQuestionTypes: {
      query: QUESTION_TYPE_LIST,
      error(e: ApolloError) {
        console.error(e);
      },
      result(
        result: ApolloQueryResult<{
          qna: { serviceQuestionTypes: ServiceQuestionType };
        }>
      ) {
        this.$data.typeList = result.data.qna.serviceQuestionTypes;
        this.$data.loadingSelect = false;
      },
      update: data => data.qna.serviceQuestionTypes,
    },
  },
});
