import gql from 'graphql-tag';
import { PROFILE_CONTENT_FRAGMENT } from '@/common/contentConstants';

export const QNA_LIST_QUERY = gql`
  query productQnas($filter: ProductQnaFilter, $page: Int, $size: Int) {
    qna {
      productQnas(filter: $filter, page: $page, size: $size) {
        totalCount
        edges {
          node {
            id
            question
            product {
              host {
                id
                name
              }
              manager {
                id
                name
              }
              id
              title
              status
            }
            user {
              id
              nickname
            }
            answer {
              answer
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            secret
            status
          }
          cursor
        }
        pageInfo {
          hasPreviousPage
          hasNextPage
          endCursor
        }
      }
    }
  }
`;

export const SERVICE_QNA_LIST_QUERY = gql`
  query serviceQnas($filter: ServiceQnaFilter, $page: Int, $size: Int) {
    qna {
      serviceQnas(filter: $filter, page: $page, size: $size) {
        totalCount
        edges {
          node {
            id
            title
            question
            createdAt
            answer {
              answer
            }
            images {
              __typename
              ...thumbnailFields
            }
            user {
              id
              nickname
              profileImage {
                id
                uri
                thumbnail
              }
            }
            questionType {
              id
              typeName
            }
            feedback
            status
          }
        }
      }
    }
  }
  ${PROFILE_CONTENT_FRAGMENT}
`;

export const QUESTION_TYPE_LIST = gql`
  query serviceQuestionTypes {
    qna {
      serviceQuestionTypes {
        id
        typeName
      }
    }
  }
`;

export const QNA_DETAIL = gql`
  query productQna($id: ID!) {
    qna {
      productQna(id: $id) {
        id
        question
        product {
          host {
            id
            name
          }
          manager {
            id
            name
          }
          id
          title
          status
        }
        user {
          id
          nickname
        }
        answer {
          answer
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        secret
        status
      }
    }
  }
`;

export const QNA_HISTORY = gql`
  query productQnaHistories($qnaId: ID!) {
    qna {
      productQnaHistories(qnaId: $qnaId) {
        content
        createdAt
        type
      }
    }
  }
`;

export const FIND_HOSTS = gql`
  query hosts($page: Int, $size: Int, $filter: HostFilter) {
    hosts(page: $page, size: $size, filter: $filter) {
      totalCount
      edges {
        node {
          id
        }
      }
    }
  }
`;

export const LISTING_PRODUCT = gql`
  query listingProducts(
    $categoryId: ID!
    $listingProductType: ListingProductType!
    $filter: ListingProductFilter
  ) {
    product {
      listingProducts(
        categoryId: $categoryId
        listingProductType: $listingProductType
        filter: $filter
      ) {
        title
        products {
          edges {
            node {
              id
            }
          }
        }
      }
    }
  }
`;

export const FIND_USER = gql`
  query users($page: Int, $size: Int, $filter: UserFilter) {
    users(page: $page, size: $size, filter: $filter) {
      edges {
        cursor
        node {
          id
        }
      }
    }
  }
`;

export const FIND_MERCHANDISER = gql`
  query merchandisers(
    $filter: MerchandiserFilterInput
    $page: Int
    $size: Int
  ) {
    product {
      merchandisers(filter: $filter, page: $page, size: $size) {
        edges {
          node {
            id
          }
        }
        totalCount
      }
    }
  }
`;

export const PRODUCTS = gql`
  query product($filter: ProductFilterInput, $page: Int, $size: Int) {
    product {
      products(filter: $filter, page: $page, size: $size) {
        edges {
          node {
            id
          }
        }
        totalCount
      }
    }
  }
`;

export const HOST_QNA_LIST = gql`
  query productQnas($filter: ProductQnaFilter, $page: Int, $size: Int) {
    qna {
      productQnas(filter: $filter, page: $page, size: $size) {
        totalCount
        edges {
          node {
            id
            question
            product {
              host {
                id
                name
              }
              manager {
                id
                name
              }
              id
              title
              status
            }
            user {
              id
              nickname
            }
            answer {
              answer
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            secret
            status
          }
          cursor
        }
        pageInfo {
          hasPreviousPage
          hasNextPage
          endCursor
        }
      }
    }
  }
`;
