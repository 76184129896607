










































































































import Vue, { VueConstructor } from 'vue';

export default (
  Vue as VueConstructor<Vue & { $refs: { [key: string]: HTMLElement } }>
).extend({
  data() {
    return {
      model: {
        email: '',
        password: '',
        rememberMe: false,
      },
      modalTitle: '',
      modalContent: '',
      loginModalStatus: false,
    };
  },
  computed: {
    authStatus() {
      return this.$store.state.authStatus;
    },
  },
  methods: {
    onSubmit() {
      console.log(this.model);
      // this will be called only after form is valid. You can do api call here to login
    },
    pressEnterKey() {
      // if (!this.validateAccountName()) return;
      this.$refs.password.focus();
    },
    login() {
      let email = this.model.email;
      let password = this.model.password;
      this.$store
        .dispatch('login', { email, password })
        .then(() => this.$router.push('/').then(() => location.reload()))
        .catch(err => {
          if (!err) {
            return;
          }
          console.log(err);
          console.error('login:', err);
          if (err.response?.data?.message) {
            this.$modal.show({
              title: '로그인 실패',
              message:
                '로그인에 실패하였습니다. 이메일, 비밀번호를 확인해주세요.',
              type: 'danger',
            });
          }
        });
    },
    showModal(title: string, content: string) {
      this.modalTitle = title;
      this.modalContent = content;
      this.loginModalStatus = true;
    },
  },
});
