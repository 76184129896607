




























































































































































import Vue from 'vue';
import draggable, { MoveEvent } from 'vuedraggable';
import BaseSwitch from '@/BaseComponents/BaseSwitch.vue';
import { ApolloError, ApolloQueryResult } from 'apollo-client';
import {
  BANNER_LIST_QUERY,
  BANNER_CURRENTS_BANNERS_QUERY,
} from '../queries/query';
import { ClientPlatform, CommonState } from '@frientrip/domain';
import { BannerService } from '../service/BannerService';
import { BannerConnection, BannerEdge, Banner } from '../model/banner';
import Spinner from '@/components/Spinner.vue';
import FripBadge from '@/components/FripComponents/FripBadge.vue';
import { apolloClient } from '@/apolloClient';

const bannerService = new BannerService(apolloClient);

interface BannerListRequestParma {
  slotId: string;
}

interface BunnerCurrentListRequestParam {
  slotId: string;
  platform: ClientPlatform;
}
export default Vue.extend({
  name: 'BannerList',
  components: {
    draggable,
    BaseSwitch,
    Spinner,
    FripBadge,
  },
  data() {
    return {
      totalCount: 0,
      slotId: this.$route.params.slotId,
      loading: 0,
      showingLoading: 0,
      banners: {
        edges: [] as BannerEdge[],
      },
      CommonState: CommonState,
      showingBanners: {} as any, // TODO: 타입 지정 문제 해결해야함
      shwoingPlatforms: [
        ClientPlatform.AndroidApp,
        ClientPlatform.iOSApp,
        ClientPlatform.Web,
        ClientPlatform.Mobile,
      ],
      isChanged: true,
      showModal: false,
      showErrorMessageTime: 0,
      errorMessage: '',
    };
  },
  computed: {
    bannerListTitle(): string {
      return `${this.slotId} 배너 목록`;
    },
  },
  methods: {
    async refreshBanners(): Promise<void> {
      if (confirm('캐시 초기화를 하시겠습니까?')) {
        try {
          await bannerService.refreshBanners(this.slotId);
          location.reload();
        } catch (error) {
          console.error(error);
          this.showErrorMessage(error.message);
        }
      }
    },
    isAnyChangedType(): string {
      return this.isChanged ? 'secondary' : 'primary';
    },
    isAnyChanged(): boolean {
      return this.isChanged;
    },
    actionUriToLink(actionUri: string): string {
      let result = actionUri;
      if (
        actionUri.search('frip://') === -1 &&
        actionUri.search('https') === -1 &&
        actionUri.search('http') === -1
      ) {
        result = `https://frip.co.kr/${actionUri}`;
      }
      return result;
    },
    platformToType(platform: ClientPlatform): string {
      let result = 'primary';
      if (platform === ClientPlatform.AndroidApp) {
        result = 'success';
      } else if (platform === ClientPlatform.iOSApp) {
        result = 'info';
      }
      return result;
    },
    showErrorMessage(message: string): void {
      this.showErrorMessageTime = 5;
      this.errorMessage = message.replace('GraphQL error:', '');
    },
    async updateBanners(): Promise<void> {
      const param = this.banners.edges.map(
        ({ node: { id, indexNo, status } }: BannerEdge) => ({
          id: Number(id),
          indexNo: indexNo,
          status: status ? CommonState.ACTIVE : CommonState.INACTIVE,
        })
      );
      console.log('param: ', param);
      if (confirm('변경 사항을 저장하시겠습니까?')) {
        try {
          const result = await bannerService.updateBannerIndexNoAndStatus(
            this.slotId,
            param
          );
          location.reload();
          // Todo validateResult
          console.log('result: ', result);
        } catch (error) {
          console.error(error);
          this.showErrorMessage(error.message);
        }
      }
    },
    getRowClass(status: string): string {
      let result = '';
      if (!status) {
        result = 'gray';
      } else {
        result = 'white';
      }
      return result;
    },
    updateStatus(e: Event, index: string): void {
      const idx = Number(index);
      if (e) {
        this.banners.edges[idx].node.status = true;
        this.updatedBannerSortEvent();
      } else {
        this.banners.edges[idx].node.status = false;
        this.banners.edges[idx].node.indexNo = 0;
      }
      this.isChanged = false;
    },
    getLocalDateTime(inputDateTime: string): string {
      let result = '';
      if (inputDateTime !== 'null') {
        result = this.$moment(inputDateTime).format('YYYY-MM-DD A HH:mm:ss ');
      }
      return result;
    },
    updateBannerMoveEvent(event: MoveEvent<BannerEdge>): boolean {
      const { draggedContext, relatedContext } = event;
      const draggedStatus = draggedContext.element.node.status;
      const relatedStatus = relatedContext.element.node.status;
      let result = false;
      if (draggedStatus && relatedStatus) {
        result = true;
        this.isChanged = false;
      }
      return result;
    },
    updatedBannerSortEvent(): void {
      this.banners.edges.forEach(
        (edge: BannerEdge, idx: number, arr: BannerEdge[]) => {
          if (edge.node.status) {
            arr[idx].node.indexNo = arr.length - idx;
          }
        }
      );
    },
    showBannerListByCurrentTime(): void {
      this.showModal = true;
    },
    countDownChanged(showErrorMessageTime: number): void {
      this.showErrorMessageTime = showErrorMessageTime;
    },
    isShowing(id: string, platform: ClientPlatform): boolean {
      const result =
        this.showingBanners?.[platform].some(
          (banner: Banner) => banner.id === id
        ) || false;
      return result;
    },
  },
  apollo: {
    bannerQuery: {
      query: BANNER_LIST_QUERY,
      variables(): BannerListRequestParma {
        return {
          slotId: this.slotId,
        };
      },
      error(error: ApolloError): void {
        console.error(error);
        this.showErrorMessage(error.message);
      },
      result(
        result: ApolloQueryResult<{ banner: { banners: BannerConnection } }>
      ): void {
        const {
          data: {
            banner: { banners },
          },
        } = result;
        this.banners.edges = bannerService.convertBannerCommonStateToStatus(
          banners.edges
        );
        this.totalCount = banners.totalCount;
      },
      update: data => data.bannerQuery,
    },
    currentWEBBanners: {
      query: BANNER_CURRENTS_BANNERS_QUERY,
      variables(): BunnerCurrentListRequestParam {
        return {
          slotId: this.slotId,
          platform: ClientPlatform.Web,
        };
      },
      error(error: ApolloError): void {
        console.error(error);
        this.showErrorMessage(error.message);
      },
      result(
        result: ApolloQueryResult<{ banner: { currents: Banner[] } }>
      ): void {
        const {
          data: {
            banner: { currents },
          },
        } = result;
        console.log('currentWEBBanners: ', result);
        Object.assign(this.showingBanners, {
          [ClientPlatform.Web]: currents,
        });
      },
      loadingKey: 'showingLoading',
      update: data => data.currentWEBBanners,
    },
    currentMOBILEBanners: {
      query: BANNER_CURRENTS_BANNERS_QUERY,
      variables(): BunnerCurrentListRequestParam {
        return {
          slotId: this.slotId,
          platform: ClientPlatform.Mobile,
        };
      },
      error(error: ApolloError): void {
        console.error(error);
        this.showErrorMessage(error.message);
      },
      result(
        result: ApolloQueryResult<{ banner: { currents: Banner[] } }>
      ): void {
        const {
          data: {
            banner: { currents },
          },
        } = result;
        console.log('currentMoblieBanners: ', result);
        Object.assign(this.showingBanners, {
          [ClientPlatform.Mobile]: currents,
        });
      },
      loadingKey: 'showingLoading',
      update: data => data.currentMOBILEBanners,
    },
    currentAOSBanners: {
      query: BANNER_CURRENTS_BANNERS_QUERY,
      variables(): BunnerCurrentListRequestParam {
        return {
          slotId: this.slotId,
          platform: ClientPlatform.AndroidApp,
        };
      },
      error(error: ApolloError): void {
        console.error(error);
        this.showErrorMessage(error.message);
      },
      result(
        result: ApolloQueryResult<{ banner: { currents: Banner[] } }>
      ): void {
        const {
          data: {
            banner: { currents },
          },
        } = result;
        console.log('currentAOSBanners: ', result);
        Object.assign(this.showingBanners, {
          [ClientPlatform.AndroidApp]: currents,
        });
      },
      loadingKey: 'showingLoading',
      update: data => data.currentAOSBanners,
    },
    currentIOSBanners: {
      query: BANNER_CURRENTS_BANNERS_QUERY,
      variables(): BunnerCurrentListRequestParam {
        return {
          slotId: this.slotId,
          platform: ClientPlatform.iOSApp,
        };
      },
      error(error: ApolloError): void {
        console.error(error);
        this.showErrorMessage(error.message);
      },
      result(
        result: ApolloQueryResult<{ banner: { currents: Banner[] } }>
      ): void {
        const {
          data: {
            banner: { currents },
          },
        } = result;
        console.log('currentiOSBanners: ', result);
        Object.assign(this.showingBanners, {
          [ClientPlatform.iOSApp]: currents,
        });
      },
      loadingKey: 'showingLoading',
      update: data => data.currentIOSBanners,
    },
  },
});
