




























































import BaseInput from '@/BaseComponents/Inputs/BaseInput.vue';
import FripButton from '@/components/FripComponents/FripButton.vue';
import Vue from 'vue';
import { getAuthUrl } from '@/env';
import axios from 'axios';
import { extend } from 'vee-validate';
import { VerificationForm } from '@/domain/login/model/identity';

enum PhoneValidationErrorCode {
  Duplicate = 'US0007',
  Expired = 'ID0003',
  InvalidValue = 'ID0005',
  ToManyRequest = 'ID0031',
}

export default Vue.extend({
  components: { BaseInput, FripButton },
  props: {
    value: String,
    disabled: Boolean,
    byHost: Boolean,
  },
  data(): {
    mobileNumber: string;
    verificationKey: string;
    verificationCode: string;
    verificationFailed: boolean;
    timer: number;
    timerIds: any[];
    fommatedTimer: string;
    verificationCompleted: boolean;
    verificationRequestLoading: boolean;
  } {
    return {
      mobileNumber: this.$props.value,
      verificationKey: '',
      verificationCode: '',
      verificationFailed: false,
      timer: 300,
      timerIds: [],
      fommatedTimer: '',
      verificationCompleted: this.disabled || false,
      verificationRequestLoading: false,
    };
  },
  watch: {
    mobileNumber(): void {
      this.mobileNumber = this.mobileNumber.replace(/[^0-9]/g, '');
    },
    verificationCode(): void {
      this.verificationCode = this.verificationCode.replace(/[^0-9]/g, '');
    },
  },
  created() {
    extend('verification', (_, param) => {
      return !(param as boolean[])[0];
    });
  },
  methods: {
    onDataChanged() {
      this.$emit('input', this.mobileNumber); // input 이벤트 발생
    },
    start() {
      this.timer = 300;
      console.log('start');
      this.timerIds.push(
        setInterval(() => {
          if (this.timer > 0) {
            this.timer--;
          }
          this.fommatedTimer = this.prettifyTime();
        }, 1000)
      );
    },
    stop() {
      // 1초에 한번씩 start 호출
      this.timer = 0;
      this.fommatedTimer = '';
      this.timerIds.map(id => clearInterval(id));
      this.verificationCompleted = true;
    },

    prettifyTime() {
      const time = this.timer / 60;
      const minutes = Math.floor(this.timer / 60);
      const seconds = Math.round((time - minutes) * 60);
      return minutes + ':' + this.pad(seconds);
    },
    pad(number: number) {
      const string = String(number);
      if (string.length === 2) {
        return string;
      }
      return '0' + string;
    },

    //이메일 형식을 확인하자
    async requestPhoneVerification() {
      try {
        const result = await axios({
          url: `${getAuthUrl()}/validation/phone/request`,
          data: {
            mobileNumber: this.mobileNumber,
          },
          method: 'POST',
        });
        this.verificationKey = result?.data?.data?.verificationKey;
        this.start();
        console.log(result);
        console.log(this.verificationKey);
        this.$bvToast.toast('인증번호가 전송되었습니다.', {
          toaster: 'b-toaster-top-center',
          variant: 'primary',
        });
      } catch (e: any) {
        this.$emit('error', e?.response?.data?.extensions?.code);

        // if (
        //   e.response.data.extensions.code === PhoneValidationErrorCode.Expired
        // ) {
        //   //만료에 대한 처리
        // }
      }
    },
    reVerify() {
      this.verificationCompleted = false;
    },
    async verifyPhoneNumber() {
      this.verificationRequestLoading = true;
      try {
        const verifyUrl = this.byHost
          ? `${getAuthUrl()}/validation/host/phone`
          : `${getAuthUrl()}/validation/phone`;
        const result = await axios({
          url: verifyUrl,
          params: {
            mobileNumber: this.mobileNumber,
            verificationKey: this.verificationKey,
            verificationCode: this.verificationCode,
          },
          method: 'GET',
        });
        // const valid = result.data.data.valid;
        // const value = result.data.data.value;
        // if (!valid) {
        //   this.$emit('fail', value);
        // } else {
        //   this.$emit('success', this.phoneNumber);
        // }
        this.stop();
        const param: VerificationForm = {
          verificationKey: this.verificationKey,
          mobileNumber: this.mobileNumber,
        };
        this.$emit('success', {
          ...param,
          verificationCode: this.verificationCode,
        });
      } catch (e: any) {
        console.log(e.response.data.extensions.code);
        this.$emit('error', e?.response?.data?.extensions?.code);
        if (
          e.response.data.extensions.code === PhoneValidationErrorCode.Duplicate
        ) {
          this.$modal.show(
            {
              title: '가입된 번호',
              message: '이미 가입된 번호입니다.',
              type: 'primary',
              confirmText: '로그인하러 가기',
              showCancelButton: true,
            },
            () => {
              this.$router.push('/');
            }
          );
        }
        if (
          e.response.data.extensions.code === PhoneValidationErrorCode.Expired
        ) {
          this.$modal.show(
            {
              title: '입력시간 만료',
              message: '인증번호 입력시간이 만료되었습니다.',
              type: 'primary',
            },
            () => {
              this.verificationKey = '';
            }
          );
        }
        if (
          e.response.data.extensions.code ===
          PhoneValidationErrorCode.InvalidValue
        ) {
          this.verificationFailed = true;
        }

        console.log(e);
      } finally {
        this.verificationRequestLoading = false;
      }
    },
  },
});
