














import Vue from 'vue';
export default Vue.extend({
  props: {
    text: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
  },
  data(): { isOpen: boolean } {
    return {
      isOpen: false,
    };
  },
});
