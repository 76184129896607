import { CommonState } from '@frientrip/domain';
import ApolloClient from 'apollo-client';
import { NormalizedCacheObject } from 'apollo-cache-inmemory';
import {
  HostBaseParam,
  Host,
  HostExtraParam,
  HostParam,
  HostAuthenticationParam,
  HostIntroducerParam,
} from '../models/host';
import {
  updateHost,
  withdrawHost,
  updateHostExtraInfo,
  approveHost,
  registHost,
  signUpHost,
  changeHostProfileImage,
  changeHostOwner,
  updateHostProfile,
  reAuthenticatePhoneNumber,
  changeHostPersonalPhoneNumber,
  certificateHost,
  decertificateHost,
  updateHostMemo,
  updateHostIntroducer,
} from '../graphqls/host';

export class HostService {
  private readonly apollo: ApolloClient<NormalizedCacheObject>;
  constructor(apollo: ApolloClient<NormalizedCacheObject>) {
    this.apollo = apollo;
  }
  public async changeProfileImage(hostId: string, contentId: string) {
    const res = await this.apollo.mutate({
      mutation: changeHostProfileImage,
      variables: {
        hostId,
        contentId,
      },
    });
    return res.data?.changeHostProfileImage as Host;
  }
  public async update(
    hostId: string,
    param1: HostBaseParam,
    param2?: HostExtraParam | null,
    memo?: string,
    introducerParam?: HostIntroducerParam
  ): Promise<Host> {
    console.log(param1);
    console.log(param2);
    let host;
    const res1 = await this.apollo.mutate({
      mutation: updateHost,
      variables: {
        hostId,
        param: param1,
      },
    });
    host = res1.data.updateHost;
    if (param2) {
      const res2 = await this.apollo.mutate({
        mutation: updateHostExtraInfo,
        variables: {
          hostId,
          param: param2,
        },
      });
      console.log(res2);
      host = res2.data.updateHostExtraInfo;
    }
    if (memo !== null && memo !== undefined) {
      const res3 = await this.apollo.mutate({
        mutation: updateHostMemo,
        variables: {
          hostId,
          memo,
        },
      });
      console.log(res3);
      host = res3.data.updateHostMemo;
    }

    if (introducerParam) {
      const res4 = await this.apollo.mutate({
        mutation: updateHostIntroducer,
        variables: {
          param: introducerParam,
        },
      });
      console.log(res4);
      host = res4.data.updateHostIntroducer;
    }

    return host;
  }
  public async updateProfile(
    hostId: string,
    param1: HostBaseParam
  ): Promise<Host> {
    console.log(param1);
    const res1 = await this.apollo.mutate({
      mutation: updateHostProfile,
      variables: {
        hostId,
        param: param1,
      },
    });
    return res1.data?.updateHost as Host;
  }

  public async delete(hostId: string): Promise<Host> {
    const res = await this.apollo.mutate({
      mutation: withdrawHost,
      variables: {
        hostId,
      },
    });
    return res.data?.withdrawHost as Host;
  }
  public async approveHost(hostId: string, approvedAt: Date, expiredAt?: Date) {
    const res = await this.apollo.mutate({
      mutation: approveHost,
      variables: {
        hostId,
        approvedAt,
        expiredAt,
      },
    });
    console.log(res);
    return res.data?.approveHost as Host;
  }
  public async regist(param: HostParam) {
    const res = await this.apollo.mutate({
      mutation: registHost,
      variables: { param },
    });
    console.log(res);
    return res.data?.registHost as Host;
  }
  public async signUp(param: HostParam) {
    const res = await this.apollo.mutate({
      mutation: signUpHost,
      variables: { param },
    });
    console.log(res);
    return res.data?.registHost as Host;
  }

  public async changeOwner(hostId: string, userId: string) {
    const res = await this.apollo.mutate({
      mutation: changeHostOwner,
      variables: { hostId, userId },
    });
    console.log(res);
    return res.data?.changeHostOwner as Host;
  }
  public async changePersonalPhoneNumber(hostId: string, phoneNumber: string) {
    const res = await this.apollo.mutate({
      mutation: changeHostPersonalPhoneNumber,
      variables: { hostId, phoneNumber },
    });
    console.log(res);
    return res.data?.changeHostPersonalPhoneNumber as Host;
  }
  public async reAuthenticatePhoneNumber(
    hostId: string,
    param: HostAuthenticationParam,
    updatePersonalInfo?: boolean
  ) {
    const res = await this.apollo.mutate({
      mutation: reAuthenticatePhoneNumber,
      variables: {
        hostId,
        param,
        updatePersonalInfo,
      },
    });
    console.log(res);
  }
  public async decertificate(hostId: string) {
    const res = await this.apollo.mutate({
      mutation: decertificateHost,
      variables: {
        hostId,
      },
    });
    console.log(res);
  }
  public async certificate(hostId: string) {
    const res = await this.apollo.mutate({
      mutation: certificateHost,
      variables: {
        hostId,
      },
    });
    console.log(res);
  }
}
