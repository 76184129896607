









import Vue from 'vue';
export default Vue.extend({
  name: 'callout',
  props: {
    title: {
      type: String,
    },
    content: {
      type: String,
    },
    type: {
      type: String,
      default: 'primary-secondary',
    },
  },
  computed: {
    textColor() {
      const color = this.type.split('-')[0] || 'primary';
      return `text-frip-${color}`;
    },
  },
});
