






























import Vue from 'vue';
import { ServiceQnaService } from '@/domain/qna/service/ServiceQnaService';
import { apolloClient } from '@/apolloClient';
import { ServiceAnswerParam, ServiceQna } from '@/domain/qna/model/serviceQna';

const serviceQnaService: ServiceQnaService = new ServiceQnaService(
  apolloClient
);
export default Vue.extend({
  name: 'ServiceAnswerCard',
  props: {
    row: {
      type: Object,
    },
  },
  data() {
    return {
      serviceQna: {},
    };
  },
  created() {
    this.serviceQna = this.row;
  },
  methods: {
    async saveAnswer(row: ServiceQna): Promise<void | boolean> {
      console.log(row);

      if (!row.answer.answer) {
        this.$modal.show({
          title: '답변 등록 실패!',
          type: 'warning',
          message: '답변을 등록해주세요.',
        });

        return false;
      }

      const param: ServiceAnswerParam = {
        id: row.id,
        answer: row.answer.answer,
      };

      this.$modal.show(
        {
          title: '저장',
          type: 'info',
          message: '댓글을 저장 하시겠습니까?',
          showCancelButton: true,
        },
        async () => {
          try {
            const answer = await serviceQnaService.registerAnswer(param);

            row.answer = answer;

            this.$modal.show({
              title: '등록 성공!',
              type: 'success',
            });
          } catch (err) {
            this.$modal.show({
              title: '등록 실패!',
              type: 'warning',
              message: err.message,
            });
          }
        },
        () => {
          return false;
        }
      );
    },
    async updateAnswer(row: ServiceQna): Promise<void | boolean> {
      if (!row.answer.answer) {
        this.$modal.show({
          title: '답변 등록 실패!',
          type: 'warning',
          message: '답변을 등록해주세요.',
        });

        return false;
      }

      const param: ServiceAnswerParam = {
        answer: row.answer.answer,
        id: row.id,
      };

      this.$modal.show(
        {
          title: '수정',
          type: 'info',
          message: '변경된 내용을 저장하시겠습니까?',
          showCancelButton: true,
          cancelText: '취소',
        },
        async (): Promise<void> => {
          try {
            await serviceQnaService.updateAnswer(param);

            this.$modal.show({
              title: '수정 성공',
              type: 'success',
              message: '답변이 수정되었습니다.',
              showCancelButton: false,
            });
          } catch (err) {
            this.$modal.show({
              title: '등록 실패!',
              type: 'warning',
              message: err.message,
            });
          }
        }
      );
    },
    async deleteAnswer(row: ServiceQna): Promise<void | boolean> {
      if (!row.id) {
        this.$modal.show({
          title: '답변 삭제 실패',
          type: 'warning',
          message: '답변을 삭제 할 수 없습니다.',
        });

        return false;
      }

      this.$modal.show(
        {
          title: '답변 삭제',
          type: 'info',
          message: '답변을 삭제 하시겠습니까?',
          showCancelButton: true,
          cancelText: '취소',
        },
        async () => {
          try {
            await serviceQnaService.deleteAnswer(row.id);

            row.answer.answer = '';
            row.answer.createdAt = '';

            this.$modal.show({
              title: '답변 삭제 완료!',
              type: 'success',
            });
          } catch (err) {
            this.$modal.show({
              title: '답변 삭제 실패',
              message: err.message,
              type: 'warning',
            });
          }
        },
        () => {
          return false;
        }
      );
    },
    async deleteQuestion(row: ServiceQna): Promise<boolean | void> {
      console.log(row);
      if (!row.id) {
        this.$modal.show({
          title: '질문 삭제 실패',
          type: 'warning',
          message: '질문을 삭제 할 수 없습니다.',
        });

        return false;
      }

      this.$modal.show(
        {
          title: '질문 삭제',
          type: 'info',
          message: '질문을 삭제 하시겠습니까?',
          showCancelButton: true,
          cancelText: '취소',
        },
        async () => {
          try {
            await serviceQnaService.deleteQuestion(row.id);

            this.$modal.show(
              {
                title: '질문 삭제 성공!',
                type: 'success',
              },
              () => {
                location.reload();
              }
            );
          } catch (err) {
            this.$modal.show({
              title: '질문 삭제 실패!',
              type: 'warning',
              message: '질문을 삭제 할 수 없습니다.',
            });
          }
        }
      );
    },
  },
});
