import ApolloClient from 'apollo-client';
import { NormalizedCacheObject } from 'apollo-cache-inmemory';

import {
  SAVE_BANNERSLOT_MUTATION,
  DELETE_BANNERSLOT_MUTATION,
} from '../queries/mutation';

interface SlotParam {
  id: string;
  name: string;
  maximumCountOfBanner: number;
  categoryId?: string;
  infos: ImageInfo[];
}

interface ImageInfo {
  id?: number;
  width: number;
  height: number;
  maximumFileSize: number;
  platforms: string[];
}

export class SlotService {
  private readonly apollo: ApolloClient<NormalizedCacheObject>;
  constructor(apollo: ApolloClient<NormalizedCacheObject>) {
    this.apollo = apollo;
  }
  // 슬롯 저장
  async saveBannerSlot(param: SlotParam, isNewSlot: boolean): Promise<void> {
    console.log('save banner slot param: ', {
      id: param.id,
      name: param.name,
      maximumCountOfBanner: Number(param.maximumCountOfBanner),
      categoryId: param.categoryId || null,
      infos: param.infos,
    });
    await this.apollo.mutate({
      mutation: SAVE_BANNERSLOT_MUTATION,
      variables: {
        param: {
          id: param.id,
          name: param.name,
          maximumCountOfBanner: Number(param.maximumCountOfBanner),
          categoryId: param.categoryId || null,
          infos: param.infos,
        },
        isNewSlot: isNewSlot,
      },
    });
  }

  // 슬롯 삭제
  async deleteBannerSlot(id: string): Promise<void> {
    await this.apollo.mutate({
      mutation: DELETE_BANNERSLOT_MUTATION,
      variables: {
        id: id,
      },
    });
  }
}
