






















































































import Vue from 'vue';
import { Option, Select } from 'element-ui';
import {
  ProductQanSearchTimeOptions,
  ProductQnaFilter,
  ProductQnaSearchKindOptions,
  ProductQnaState,
} from '@/domain/qna/model/productQna';
import { isHostAdmin } from '@/env';
import { HostEdge, HostFilter } from '@/domain/qna/model/host';
import { HostService } from '@/domain/qna/service/HostService';
import { apolloClient } from '@/apolloClient';
import { UserService } from '@/domain/qna/service/UserService';
import { UserEdge, UserFilter } from '@/domain/qna/model/user';
import { MerchandiserFilter } from '@/domain/qna/model/merchandiser';
import { MerchandiserService } from '@/domain/qna/service/MerchandiserService';
import { ProductService } from '@/domain/qna/service/ProductService';
import { ProductEdge, ProductFilter } from '@/domain/qna/model/product';

const hostService = new HostService(apolloClient);
const userService = new UserService(apolloClient);
const merchandiserService = new MerchandiserService(apolloClient);
const productService = new ProductService(apolloClient);

export default Vue.extend({
  name: 'ProductQnaSearchFilter',
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
  },
  props: {
    totalCount: {
      type: Number,
    },
  },
  data(): {
    searchTimeOptions: [
      { value: string; text: string },
      { value: string; text: string }
    ];
    searchKindOptions: [
      { value: string; text: string },
      { value: string; text: string },
      { value: string; text: string }
    ];

    productQnaType: [
      { value: string; text: string },
      { value: string; text: string },
      { value: string; text: string },
      { value: string; text: string }
    ];
    searchKindValue: ProductQnaSearchKindOptions;
    kindValue: string;
    productQnaTypeValue: ProductQnaState[];
    host: string;
    manager: string;
    termOptionValue: string;
    filter: ProductQnaFilter;
    checkedConfirmedProductQna: boolean;
    termType: ProductQanSearchTimeOptions;
  } {
    return {
      searchTimeOptions: [
        {
          value: ProductQanSearchTimeOptions.QUESTION_TIME,
          text: '최종 문의일',
        },
        { value: ProductQanSearchTimeOptions.ANSWER_TIME, text: '최종 답변일' },
      ],
      searchKindOptions: [
        {
          value: ProductQnaSearchKindOptions.PRODUCT,
          text: '상품명',
        },
        {
          value: ProductQnaSearchKindOptions.QUESTION,
          text: '문의 내용',
        },
        {
          value: ProductQnaSearchKindOptions.USER,
          text: '문의한 크루',
        },
      ],
      productQnaType: [
        {
          value: ProductQnaState.NEW,
          text: '신규문의',
        },
        {
          value: ProductQnaState.CONFIRM,
          text: '확인필요',
        },
        {
          value: ProductQnaState.COMPLETE,
          text: '답변완료',
        },
        {
          value: ProductQnaState.BLIND,
          text: '블라인드',
        },
      ],
      searchKindValue: ProductQnaSearchKindOptions.PRODUCT,
      kindValue: '',
      productQnaTypeValue: [
        ProductQnaState.NEW,
        ProductQnaState.COMPLETE,
        ProductQnaState.BLIND,
        ProductQnaState.CONFIRM,
      ],
      host: '',
      manager: '',
      termOptionValue: 'all',
      filter: {
        isAnswering: true,
        statusIn: [
          ProductQnaState.NEW,
          ProductQnaState.BLIND,
          ProductQnaState.CONFIRM,
          ProductQnaState.COMPLETE,
        ],
        term: {},
      },
      checkedConfirmedProductQna: false,
      termType: ProductQanSearchTimeOptions.QUESTION_TIME,
    };
  },
  computed: {
    checkAuthority(): boolean {
      return !isHostAdmin();
    },
    qnaTypeValue: {
      get(): ProductQnaState[] {
        return (this as any).$data.productQnaTypeValue;
      },
      set(newValue) {
        (this as any).$data.productQnaTypeValue = newValue;
      },
    },
  },
  methods: {
    async search(): Promise<void> {
      if (this.host) {
        const hostFilter: HostFilter = {
          nameOrIdLike: this.host,
        };

        await this.findHostByName(hostFilter);
      }

      switch (this.searchKindValue) {
        case ProductQnaSearchKindOptions.PRODUCT:
          if (this.kindValue) {
            this.filter.content = '';
            this.filter.userIds = undefined;
            await this.findProductByName(this.kindValue);
          } else {
            this.filter.productIds = undefined;
          }

          break;

        case ProductQnaSearchKindOptions.QUESTION:
          if (this.kindValue) {
            this.filter.userIds = undefined;
            this.filter.productIds = undefined;
            this.filter.content = this.kindValue;
          } else {
            this.filter.content = '';
          }

          break;

        case ProductQnaSearchKindOptions.USER:
          if (this.kindValue) {
            this.filter.content = '';
            this.filter.productIds = undefined;
            await this.findUserByName(this.kindValue);
          } else {
            this.filter.userIds = undefined;
          }

          break;
      }

      this.filter.statusIn = this.$data.productQnaTypeValue;
      this.filter.term!.type = this.$data.termType;

      this.$emit('addFilter', this.filter);
    },
    initSearchValues(): void {
      this.searchKindValue = ProductQnaSearchKindOptions.PRODUCT;
      this.kindValue = '';
      this.productQnaTypeValue = [
        ProductQnaState.NEW,
        ProductQnaState.COMPLETE,
        ProductQnaState.BLIND,
        ProductQnaState.CONFIRM,
      ];
      this.host = '';
      this.manager = '';
      this.termOptionValue = 'all';
      this.filter = {
        term: {
          type: ProductQanSearchTimeOptions.QUESTION_TIME,
        },
      };
    },
    termInput(): void {
      this.filter.term!.type =
        this.filter.term!.type ?? ProductQanSearchTimeOptions.QUESTION_TIME;
    },
    async findHostByName(filter: HostFilter): Promise<void> {
      try {
        const response = await hostService.getHosts(filter);

        if (response.edges.length === 0) {
          return;
        }

        this.filter.hostIds = response.edges.map((edge: HostEdge) => {
          return edge.node.id;
        });
      } catch (err) {
        this.$emit('error', err.message);
      }
    },
    async findProductByName(searchString: string): Promise<void> {
      try {
        const productFilter: ProductFilter = {
          titleLike: searchString,
        };
        const response = await productService.getProduct(productFilter);

        if (response.edges.length === 0) {
          this.filter.productIds = undefined;
          return;
        }

        this.filter.productIds = response.edges.map((edge: ProductEdge) => {
          return edge.node.id;
        });
      } catch (err) {
        this.$emit('error', err.message);
      }
    },
    async findUserByName(userName: string): Promise<void> {
      try {
        const userFilter: UserFilter = {
          nicknameLike: userName,
        };

        const response = await userService.getUser(userFilter);

        if (response.edges.length === 0) {
          return;
        }

        this.filter.userIds = response.edges.map((edge: UserEdge) => {
          return edge.node.id;
        });
      } catch (err) {
        this.$emit('error', err.message);
      }
    },
    async findMerchandiserByName(name: string): Promise<void> {
      try {
        const merchandiserFilter: MerchandiserFilter = {
          nameLike: name,
        };

        const response = await merchandiserService.getMerchandiser(
          merchandiserFilter
        );

        if (response.edges.length === 0) {
          return;
        }

        const managerId = response.edges[0].node.id ?? null;
      } catch (err) {
        this.$emit('error', err.message);
      }
    },
    confirmedProductQna(): void {
      if (this.$data.checkedConfirmedProductQna) {
        this.initSearchValues();
        this.filter.statusIn = [ProductQnaState.NEW, ProductQnaState.CONFIRM];
      } else {
        this.initSearchValues();
      }

      this.$emit('addFilter', this.filter);
    },
  },
});
