import ApolloClient from 'apollo-client';
import { NormalizedCacheObject } from 'apollo-cache-inmemory';
import { ProductFilter } from '@/domain/product/model/product/param/productSearchFilterParam';
import { ProductConnection } from '@/domain/qna/model/product';
import { PRODUCTS } from '@/domain/qna/queries/query';

export class ProductService {
  private readonly apollo: ApolloClient<NormalizedCacheObject>;

  constructor(apollo: ApolloClient<NormalizedCacheObject>) {
    this.apollo = apollo;
  }

  async getProduct(filter: ProductFilter): Promise<ProductConnection> {
    const response = await this.apollo.query({
      query: PRODUCTS,
      variables: { filter },
    });

    console.log(response);

    return response.data.product.products;
  }
}
