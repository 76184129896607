





























































































































































































import { QNA_DETAIL, QNA_HISTORY } from '@/domain/qna/queries/query';
import { ApolloError, ApolloQueryResult } from 'apollo-client';
import {
  ProductAnswer,
  ProductAnswerParam,
  ProductQna,
  ProductQnaHistory,
  ProductQnaHistoryType,
  ProductQnaState,
} from '@/domain/qna/model/productQna';
import Spinner from '@/components/Spinner.vue';
import ProductQnaHistoryList from '@/domain/qna/component/ProductQnaHistoryList.vue';
import { ProductQnaService } from '@/domain/qna/service/ProductQnaService';
import { apolloClient } from '@/apolloClient';
import { isHostAdmin } from '@/env';
import FripFooter from '@/components/FripFooter.vue';
import { ContainerMixin } from '@/common/mixin/containerMixin';

const productQnaService: ProductQnaService = new ProductQnaService(
  apolloClient
);

export default ContainerMixin.extend({
  name: 'ProductQnaDetail',
  components: {
    FripFooter,
    ProductQnaHistoryList,
    Spinner,
  },
  data(): {
    id: string | (string | null)[];
    qna: ProductQna;
    isLoading: boolean;
    questionHistoryCount: number;
    answerHistoryCount: number;
    questionHistories: ProductQnaHistory[];
    answerHistories: ProductQnaHistory[];
    showErrorMessageTime: number;
    errorMessage: string;
    editable: boolean;
    prevPage: number;
  } {
    return {
      id: this.$route.params.id,
      qna: {} as ProductQna,
      isLoading: false,
      questionHistoryCount: 0,
      answerHistoryCount: 0,
      questionHistories: [] as ProductQnaHistory[],
      answerHistories: [] as ProductQnaHistory[],
      showErrorMessageTime: 0,
      errorMessage: '',
      editable: true,
      prevPage: 1,
    };
  },
  computed: {
    checkAuthority() {
      return !isHostAdmin();
    },
    checkQnaStatus(): boolean {
      return (
        this.$data.qna.status === ProductQnaState.BLIND ||
        this.$data.qna.status === ProductQnaState.CONFIRM
      );
    },
    checkAnswerStatus(): boolean {
      if (this.$data.qna.answer?.answer === '') {
        return false;
      }

      if (
        this.$data.qna.status === ProductQnaState.CONFIRM ||
        this.$data.qna.status === ProductQnaState.BLIND
      ) {
        return false;
      }

      return true;
    },
    disabledByBlindState(): boolean {
      return this.$data.qna.status === ProductQnaState.BLIND;
    },
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (from.path === '/qna/list') {
        vm.$data.prevPage = from.query.page ?? 1;
      }
    });
  },
  methods: {
    async blindAnswer(id: number): Promise<void> {
      this.$modal.show(
        {
          title: '답변 블라인드 처리',
          message: '답변을 블라인드 처리 하시겠습니까?',
          type: 'info',
          showCancelButton: true,
          cancelText: '취소',
        },
        async () => {
          try {
            await productQnaService.inactivateAnswer(id);
            this.$notify({
              title: '답변 블라인드',
              message: '블라인드 처리 되었습니다.',
              type: 'success',
            });
            await this.$apollo.queries.productQna.refetch();
            await this.$apollo.queries.productQnaHistory.refetch();
          } catch (err) {
            console.log(err);
            this.showErrorAlert(err.message);
          }
        },
        () => {
          return false;
        }
      );
    },
    async blindQuestion(id: number): Promise<void> {
      console.log(id);
      this.$modal.show(
        {
          title: '문의 블라인드 처리',
          html: '<span>문의를 블라인드 처리 하시겠습니까?</br>답변이 있는 경우 함께 블라인드 처리됩니다.</span>',
          type: 'info',
          showCancelButton: true,
          cancelText: '취소',
        },
        async () => {
          try {
            await productQnaService.inactivateQuestion(id);
            this.$notify({
              title: '문의 블라인드',
              message: '블라인드 처리 되었습니다.',
              type: 'success',
            });
            await this.$apollo.queries.productQna.refetch();
            await this.$apollo.queries.productQnaHistory.refetch();
          } catch (err) {
            console.log(err);
            this.showErrorAlert(err.message);
          }
        },
        () => {
          return false;
        }
      );
    },
    countDownChanged(showErrorMessageTime: number): void {
      this.$data.showErrorMessageTime = showErrorMessageTime;
    },
    showErrorMessage(message: string): void {
      this.$data.showErrorMessageTime = 5;
      this.$data.errorMessage = message.replace('GraphQL error:', '');
    },
    async registerAnswer(): Promise<ProductAnswer | void> {
      if (
        !this.$data.qna.answer.answer ||
        this.$data.qna.answer?.answer.trim().length < 5
      ) {
        this.$modal.show({
          title: '답변 등록 실패!',
          message: '답변은 최소 5자 이상 입력해주세요.',
          type: 'warning',
        });

        return;
      }

      this.$modal.show(
        {
          title: '답변 등록',
          message: '답변을 등록 하시겠습니까?',
          type: 'info',
          showCancelButton: true,
          cancelText: '취소',
        },
        async () => {
          try {
            const answerParam: ProductAnswerParam = {
              id: this.$data.id,
              answer: this.$data.qna.answer.answer,
            };
            //row에 답변과 문의글이 한번에 있어서 id로 수정할지 등록할지 판단을 할 수가 없음.. createdAt으로 판단??..
            console.log(this.$data.qna.createdAt);
            if (this.$data.qna.answer.createdAt !== '') {
              this.$data.qna.answer = await productQnaService.updateAnswer(
                answerParam
              );
            } else {
              this.$data.qna.answer = await productQnaService.createAnswer(
                answerParam
              );
            }
            this.$data.qna.status = ProductQnaState.COMPLETE;
            this.$data.editable = true;
            this.$notify({
              title: '답변 등록!',
              message: '답변 등록 완료',
              type: 'success',
            });
          } catch (err) {
            this.showErrorAlert(err.message);
          }
        }
      );
    },
    typeName(type: string) {
      switch (type) {
        case ProductQnaState.NEW:
          return '신규문의';
        case ProductQnaState.CONFIRM:
          return '확인필요';
        case ProductQnaState.COMPLETE:
          return '답변완료';
        case ProductQnaState.BLIND:
          return '블라인드';
      }
    },
    badgeType(type: string) {
      switch (type) {
        case ProductQnaState.CONFIRM:
          return 'qna-confirm-badge';
        case ProductQnaState.COMPLETE:
          return 'qna-complete-badge';
        case ProductQnaState.BLIND:
          return 'qna-blind-badge';
        default:
          return 'qna-new-badge';
      }
    },
    modifyAnswer(): void {
      this.$data.editable = false;
    },
    openProductQnaList(): void {
      this.$router.push(`/qna/list?page=${this.$data.prevPage}`);
    },
    toTop(): void {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },
    toBottom(): void {
      // TODO: 구현 필요함
      window.scrollTo({ top: 5000, behavior: 'smooth' });
    },
    hostDetail(): void {
      const hostId = this.$data.qna.product!.host!.id;
      this.$router.push(`/host/list/${hostId}`);
    },
    productDetail(): void {
      const productId = this.$data.qna.product.id;
      this.$router.push(`/product/list/${productId}`);
    },
    cancelEditing(): void {
      this.$data.editable = true;
    },
  },
  apollo: {
    productQna: {
      query: QNA_DETAIL,
      variables() {
        return { id: this.$data.id };
      },
      error(e: ApolloError) {
        console.error(e);
      },
      result(
        result: ApolloQueryResult<{
          qna: { productQna: ProductQna };
        }>
      ) {
        this.$data.qna = result.data.qna.productQna;
        if (!this.$data.qna.answer) {
          this.$data.qna.answer = {
            answer: '',
            createdAt: '',
            updatedAt: '',
          };

          this.$data.editable = false;
        }

        this.$data.isLoading = true;
      },
      update: data => data.qna.productQnas,
    },
    productQnaHistory: {
      query: QNA_HISTORY,
      variables() {
        return { qnaId: this.$data.id };
      },
      error(e: ApolloError) {
        console.error(e);
      },
      result(
        result: ApolloQueryResult<{
          qna: { productQnaHistories: ProductQnaHistory[] };
        }>
      ) {
        this.$data.questionHistories =
          result.data.qna.productQnaHistories.filter(history => {
            if (history.type === ProductQnaHistoryType.QUESTION) {
              return history;
            }
          });

        this.$data.answerHistories = result.data.qna.productQnaHistories.filter(
          history => {
            if (history.type === ProductQnaHistoryType.ANSWER) {
              return history;
            }
          }
        );

        this.$data.questionHistoryCount = this.$data.questionHistories.length;
        this.$data.answerHistoryCount = this.$data.answerHistories.length;
      },
      update: data => data.qna.productQnaHistories,
      skip(): boolean {
        return isHostAdmin();
      },
    },
  },
});
