export const PRODUCT_TAB_ENUM = {
  DEFAULT: '기본 정보',
  SALES: '판매 정보',
  DESCRIPTION: '프립 설명',
  ADDITIONAL: '부가 정보',
  PURCHASE: '거래 정보',
};

export const VALIDATION_ERROR_PRODUCT_TAB_MAP: {
  [key: string]: string;
} = {
  attachedToSchedule: PRODUCT_TAB_ENUM.SALES,
  saleTerm: PRODUCT_TAB_ENUM.SALES,
  ticketCount: PRODUCT_TAB_ENUM.SALES,
  representative: PRODUCT_TAB_ENUM.SALES,
  curriculumSection: PRODUCT_TAB_ENUM.ADDITIONAL,
  absentFee: PRODUCT_TAB_ENUM.SALES,
  commission: PRODUCT_TAB_ENUM.SALES,
  name: PRODUCT_TAB_ENUM.SALES,
  item: PRODUCT_TAB_ENUM.SALES,
  attribute: PRODUCT_TAB_ENUM.DEFAULT,
  title: PRODUCT_TAB_ENUM.DEFAULT,
  hostId: PRODUCT_TAB_ENUM.DEFAULT,
  standardCategories: PRODUCT_TAB_ENUM.DEFAULT,
  contents: PRODUCT_TAB_ENUM.DESCRIPTION,
  headerContents: PRODUCT_TAB_ENUM.DESCRIPTION,
  productKind: PRODUCT_TAB_ENUM.DEFAULT,
  categories: PRODUCT_TAB_ENUM.DEFAULT,
  schedule: PRODUCT_TAB_ENUM.SALES,
  term: PRODUCT_TAB_ENUM.SALES,
};

export const ACCESS_DENIED = '권한이 없습니다.';
export const UNAUTHORIZED = '로그인 상태가 아닙니다.';

/**
 * 서버와 통신 중에 발생한 error 를 parsing 한다.
 */
export function parseApiError(error: any): ApiError {
  let message = '서버와의 연결이 원활하지 않습니다.';
  let errors: ValidationError[] = [];
  let exception: ApiException | null = null;

  if (error.message) {
    message = error.message;
  }

  if (error.graphQLErrors && error.graphQLErrors.length > 0) {
    message = error.graphQLErrors[0].message;

    const graphQLErrors = error.graphQLErrors as GraphQLError[];

    if (graphQLErrors[0]?.extensions?.exception) {
      exception = graphQLErrors[0]?.extensions?.exception;
    }
    if (exception?.name == 'ValidationException') {
      errors = (exception as ValidationException).errors;
    }
    // errors = (exception as ValidationException).errors;
  }

  if (
    error.networkError &&
    error.networkError.result &&
    error.networkError.result.errors &&
    error.networkError.result.errors.some(
      (error: any) => 'Access denied' === error.message
    )
  ) {
    message = ACCESS_DENIED;
  }

  if (
    error.networkError &&
    error.networkError.result &&
    error.networkError.result.errors &&
    error.networkError.result.errors.some(
      (error: any) => 'Unauthorized' === error.message
    )
  ) {
    message = UNAUTHORIZED;
  }

  return {
    message,
    errors,
    exception,
  };
}

export function parseValidationErrorToProductTab(
  errors: ValidationError[]
): string[] {
  return errors.map(error => VALIDATION_ERROR_PRODUCT_TAB_MAP[error.fieldName]);
}

export interface ApiError {
  message: string;
  errors: ValidationError[];
  exception: ApiException | null;
}

export interface GraphQLError {
  message: string;
  extensions: GraphQLErrorExtensions;
  path: string[];
}

export interface GraphQLErrorExtensions {
  code: string;
  exception?: ApiException;
  serviceName: string;
  variables: any;
}

export interface ApiException {
  code: string;
  name: string;
  stacktrace: string[];
}

export interface ValidationException extends ApiException {
  errors: ValidationError[];
}

export interface ValidationError {
  fieldName: string;
  message: string;
}
