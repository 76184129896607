




























































import Vue from 'vue';
import Card from '@/BaseComponents/Cards/Card.vue';

export default Vue.extend({
  name: 'ServiceQnaStatusCard',
  components: {
    Card,
  },
  props: {
    serviceQna: Object,
  },
});
