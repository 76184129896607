import BaseKinesisEvent from './BaseKinesisEvent';

class RegisterTypeClickEvent extends BaseKinesisEvent {
  constructor(
    location: 'register' | 'login',
    private readonly join_type: 'kakao' | 'facebook' | 'email'
  ) {
    super('register_type_click', location);
  }
}

export default RegisterTypeClickEvent;
