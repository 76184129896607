





















import Vue from 'vue';

export default Vue.extend({
  props: {
    imageInfo: {
      type: Object,
      required: true,
      validator: function (value) {
        if (!value.width || !value.height || !value.maximumFileSize)
          return false;
        if (!value.platforms) return false;
        return true;
      },
    },
  },
});
