export enum SocialLinkProviderType {
  KAKAO_SYNC = 'KAKAO_SYNC',
  FACEBOOK = 'FACEBOOK',
  APPLE = 'APPLE',
}

export enum NoticeMediaChannel {
  EMAIL,
  SMS,
  PUSH,
}

export enum UserState {
  ACTIVE,
  INACTIVE,
  WITHDRAWAL,
}

export interface UserUpdateParam {
  email?: string;
  nickname?: string;
  birth?: string;
  gender?: string;
}

export interface SocialSignUpParam {
  email?: string;
  nickname: string;
  mobileNumber: string;
  profileImageUrl: string;
  socialUserId: string;
  gender?: string;
  birth?: string;
  noticeReceiveAgreements: NoticeReceiveParam[];
  provider: SocialLinkProviderType;
  socialProfile?: string;
  socialCredentials?: string;
}

export interface SignUpParam {
  email: string;
  password: string;
  nickname: string;
  mobileNumber: string;
  noticeReceiveAgreements: NoticeReceiveParam[];
}

export interface SocialLinkFilter {
  provider?: SocialLinkProviderType;
}

export interface NoticeReceiveParam {
  channel: NoticeMediaChannel;
  advertised: boolean;
  agreed: boolean;
  store?: string;
}

export interface UserFilter {
  id?: string;
  nicknameLike?: string;
  emailLike?: string;
  mobileNumberLike?: string;
}

export interface UserConnection {
  totalCount: number;
  pageInfo: PageInfo;
  edges: UserEdge[];
}

export interface UserEdge {
  cursor: string;
  node: User;
}

export interface PageInfo {
  hasNextPage: boolean;
  endCursor?: string;
}

export interface NoticeReceiveAgreement {
  id: string;
  channel: NoticeMediaChannel;
  advertised: boolean;
  agreed: boolean;
  agreedAt?: Date;
  updatedAt?: Date;
}

export interface UserTag {
  id: string;
  tagTemplateId: number;
  number: string;
}

//   export interface MyInfo {
//     "dummy id (사용안함)"
//     id: ID!
//     user: User!
//   }

export interface User {
  id: string;
  email: string;
  mobileNumber: string;
  nickname: string;
  description: string;
  birth?: string;
  gender: string;
  noticeReceiveAgreements: NoticeReceiveAgreement[];
  socialLinks: SocialLink[];
  privateProfile: boolean;
  joinedAt?: Date;
  certificated: boolean;
  verified: boolean;
  invitationCode?: string;
  referee?: User;
  status?: UserState;
  usablePoint: {
    total: number;
  };
  tags: UserTag[];
}

//   export interface UserProfile  {
//     id: ID!

//     "닉네임: 중복 불가"
//     nickname: string
//   }

('외부 계정 정보');
export interface SocialLink {
  id: string;
  socialUserId: string;
  provider: SocialLinkProviderType;
}

('초대 코드 정보');
export interface UserInvitationCode {
  code: string;
  userId: string;
}

export interface UserRefereeMap {
  userId: string;
  refereeId: string;
}

export interface RefereeRegisterParam {
  userId: string;
  refereeInvitationCode: string;
}
