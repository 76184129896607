var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('content-card',{attrs:{"notification":"슬롯 이름을 누르면 배너 목록으로 이동합니다.","title":"배너 슬롯 리스트","subtitle":_vm.totalCount.toString()},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('b-card-footer',[_c('b-row',{staticClass:"float-right mx-3"},[_c('frip-button',{attrs:{"type":"primary","to":"./create","loading":_vm.loading > 0}},[_vm._v("슬롯 등록")])],1)],1)]},proxy:true}])},[(_vm.loading > 0)?_c('spinner'):_c('el-table',{ref:"table",staticClass:"table table-responsive table-flush table-striped align-items-center mb-0",staticStyle:{"text-align":"center"},attrs:{"header-row-class-name":"thead-light","data":_vm.slots}},[_c('el-table-column',{attrs:{"label":"id","prop":"id","min-width":160},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.id)+" ")]}}])}),_c('el-table-column',{attrs:{"label":"name","prop":"name","min-width":100},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('router-link',{attrs:{"to":{
              name: 'BannerList',
              params: { slotId: row.id },
            }}},[_vm._v(" "+_vm._s(row.name)+" ")])]}}])}),_c('el-table-column',{attrs:{"label":"카테고리 ID","prop":"category"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var row = ref.row;
return [_vm._v(" "+_vm._s(row.categoryId ? row.categoryId : '')+" ")]}}])}),_c('el-table-column',{attrs:{"label":"노출 배너 최대 개수","prop":"maximumCountOfBanner"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var row = ref.row;
return [_vm._v(" "+_vm._s(row.maximumCountOfBanner)+" ")]}}])}),_c('el-table-column',{attrs:{"label":"수정","prop":"edit"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var row = ref.row;
return [_c('router-link',{attrs:{"to":{
              name: 'BannerSlotUpdate',
              params: { slotId: row.id },
            }}},[_c('frip-button',{staticClass:"my-1",attrs:{"size":"sm","outline":""}},[_vm._v("수정")])],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }