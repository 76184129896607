










































































































import Vue from 'vue';
import { Select, Option } from 'element-ui';
import { platforms, getPlatformSelectOptions } from '@/common/platforms';
import ImageInfoLabel from '@/components/Labels/ImageInfoLabel.vue';
import Callout from '@/components/Labels/Callout.vue';

export default Vue.extend({
  name: 'image-info-form',
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    Callout,
    ImageInfoLabel,
  },
  props: {
    defaultImageInfos: {
      type: Array,
    },
  },

  data() {
    return {
      form: {
        width: '',
        height: '',
        maximumFileSize: '',
        platforms: [],
      },
      recommendedImageSizes: [
        {
          label: 'Web',
          value: { width: 1860, height: 744, maximumFileSize: 2 },
        },
        {
          label: 'Mobile',
          value: { width: 1860, height: 744, maximumFileSize: 2 },
        },
        {
          label: 'Android',
          value: { width: 1860, height: 744, maximumFileSize: 2 },
        },
        {
          label: 'iOS',
          value: { width: 1860, height: 744, maximumFileSize: 2 },
        },
      ],
      showErrorMessageTime: 0,
      errorMessage: '',
      platforms: platforms,
      imageInfos: this.defaultImageInfos || [],
      platformSelectOptions: getPlatformSelectOptions(),
      selects: {
        simple: 'Web',
        multiple: ['Web', 'Mobile'],
      },
    };
  },
  methods: {
    countDownChanged(showErrorMessageTime: number) {
      this.showErrorMessageTime = showErrorMessageTime;
    },
    invalidInput() {
      console.log('invalid input!!');
    },
    addImageInfo() {
      this.imageInfos.push({
        width: Number(this.form.width),
        height: Number(this.form.height),
        maximumFileSize: Number(this.form.maximumFileSize),
        platforms: this.form.platforms,
      });
      this.$emit('input', this.imageInfos);
    },
    deleteImageInfo(index: number) {
      this.imageInfos.splice(index, 1);
    },
  },
});
