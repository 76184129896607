/* eslint-disable @typescript-eslint/no-explicit-any */
import { CommonState } from '@frientrip/domain';
import {
  ActionUriParam,
  BannerEdge,
  BannerParam,
  ImageParam,
} from '../model/banner';
import {
  CREATE_BANNER_MUTATION,
  UPDATE_BANNER_MUTATION,
  DELETE_BANNER_MUTATION,
  UPDATE_BANNERS_INDEXNO_AND_STATUS_BY_SLOTID_MUTATION,
  REFRESH_BANNERS_MUTATION,
} from '../queries/mutation';
import ApolloClient from 'apollo-client';
import { NormalizedCacheObject } from 'apollo-cache-inmemory';
import {
  imageUploadService,
  ContentTarget,
  ContentTargetWithIdParam,
} from '@/components/ImageUpload/imageUploadService';

interface BannerIndexNoAndStatusUpdateParam {
  id: number;
  indexNo: number;
  status: CommonState;
}

export class BannerService {
  private readonly apollo: ApolloClient<NormalizedCacheObject>;
  constructor(apollo: ApolloClient<NormalizedCacheObject>) {
    this.apollo = apollo;
  }

  // 배너 생성
  async createBanner(slotId: string, form: BannerParam): Promise<void> {
    for (const actionUri of form.actionUris) {
      if (actionUri.platforms.length === 0) {
        throw new Error('플랫폼은 최소 하나 이상 입력해야합니다!');
      }
    }
    console.log('form: ', form);
    const result = await this.apollo.mutate({
      mutation: CREATE_BANNER_MUTATION,
      variables: {
        param: {
          buttonText: form.buttonText,
          slotId: slotId,
          title: form.title,
          description: form.description,
          beginShowingAt:
            form.beginShowingAt !== '' ? form.beginShowingAt : null,
          endShowingAt: form.endShowingAt !== '' ? form.endShowingAt : null,
          actionUris: form.actionUris.map((actionUriParam: ActionUriParam) => {
            return {
              id: actionUriParam.id,
              actionUri: actionUriParam.actionUri,
              platforms: actionUriParam.platforms,
            };
          }),
          images: form.images
            .filter((image: ImageParam) => image.contentId !== '')
            .map((image: ImageParam) => {
              return {
                id: image.id,
                imageInfoId: image.imageInfoId,
                contentId: image.contentId,
                testType: image.testType,
              };
            }),
        },
      },
    });
    const imageContents = result.data.createBanner.contents;

    const contentIdsToBeFinalized: ContentTargetWithIdParam[] = form.images.map(
      image => {
        return {
          contentId: image.contentId,
          targetId: imageContents.filter(
            (imageContent: any) => imageContent.content.id === image.contentId
          )[0]?.id,
          target: ContentTarget.BANNER,
        };
      }
    );
    await imageUploadService.finalizeContentsAndTarget(contentIdsToBeFinalized);
  }
  // 배너 수정
  async updateBanner(
    bannerId: number,
    slotId: string,
    form: BannerParam
  ): Promise<void> {
    for (const actionUri of form.actionUris) {
      if (actionUri.platforms.length === 0) {
        throw new Error('플랫폼은 최소 하나 이상 입력해야합니다!');
      }
    }
    const result = await this.apollo.mutate({
      mutation: UPDATE_BANNER_MUTATION,
      variables: {
        id: bannerId,
        param: {
          slotId: slotId,
          title: form.title,
          buttonText: form.buttonText,
          description: form.description,
          beginShowingAt:
            form.beginShowingAt !== '' ? form.beginShowingAt : null,
          endShowingAt: form.endShowingAt !== '' ? form.endShowingAt : null,
          actionUris: form.actionUris.map((actionUriParam: ActionUriParam) => {
            return {
              id: actionUriParam.id,
              actionUri: actionUriParam.actionUri,
              platforms: actionUriParam.platforms,
            };
          }),
          images: form.images
            .filter(image => image.contentId !== '')
            .map(image => {
              return {
                id: image.id,
                imageInfoId: image.imageInfoId,
                contentId: image.contentId,
                testType: image.testType,
              };
            }),
        },
      },
    });
    const imageContents = result.data.updateBanner.contents;

    const contentIdsToBeFinalized: ContentTargetWithIdParam[] = form.images
      .filter(image => !image.id)
      .map(image => {
        return {
          contentId: image.contentId,
          targetId: imageContents.filter(
            (imageContent: any) => imageContent.content.id === image.contentId
          )[0]?.id,
          target: ContentTarget.BANNER,
        };
      });
    console.log('contentIdsToBeFinalized: ', contentIdsToBeFinalized);
    if (contentIdsToBeFinalized.length) {
      await imageUploadService.finalizeContentsAndTarget(
        contentIdsToBeFinalized
      );
    }
  }

  // 배너 삭제
  async deleteBanner(bannerId: number): Promise<void> {
    await this.apollo.mutate({
      mutation: DELETE_BANNER_MUTATION,
      variables: {
        id: bannerId,
      },
    });
  }

  // banners 캐시 초기화
  async refreshBanners(slotId: string): Promise<void> {
    await this.apollo.mutate({
      mutation: REFRESH_BANNERS_MUTATION,
      variables: {
        slotId: slotId,
      },
    });
  }

  async updateBannerIndexNoAndStatus(
    slotId: string,
    params: BannerIndexNoAndStatusUpdateParam[]
  ): Promise<void> {
    await this.apollo.mutate({
      mutation: UPDATE_BANNERS_INDEXNO_AND_STATUS_BY_SLOTID_MUTATION,
      variables: {
        slotId: slotId,
        params: params,
      },
    });
  }

  public convertBannerCommonStateToStatus(edges: BannerEdge[]): BannerEdge[] {
    console.log('edges: ', edges);
    const convertedEdges = edges.map(edge => {
      const node = edge.node;
      const { status, ...rest } = node;

      return {
        node: {
          ...rest,
          status: status === CommonState.ACTIVE ? true : false,
        },
        cursor: edge.cursor,
      };
    });
    console.log('convertedEdges: ', convertedEdges);
    return convertedEdges;
  }
}
