var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('content-card',{attrs:{"title":_vm.bannerListTitle,"subtitle":_vm.totalCount.toString()},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('b-card-footer',[_c('b-row',{staticClass:"float-right"},[_c('frip-button',{staticClass:"mx-2",attrs:{"type":"default","outline":""},on:{"click":_vm.refreshBanners}},[_vm._v("캐시 초기화")]),_c('frip-button',{staticClass:"mx-2",attrs:{"type":_vm.isAnyChangedType(),"disabled":_vm.isAnyChanged()},on:{"click":_vm.updateBanners}},[_vm._v("변경 사항 저장")]),_c('frip-button',{attrs:{"type":"secondary","to":"./create"}},[_vm._v("배너 등록")])],1)],1)]},proxy:true}])},[(_vm.loading > 0)?_c('spinner'):_c('table',{staticClass:"table border"},[_c('thead',{staticClass:"thead-light"},[_c('tr',[_c('th',{attrs:{"scope":"col"}},[_vm._v("노출 순서")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("ID")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("타이틀")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("썸네일")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("url")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("예약")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("AOS")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("IOS")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("WEB")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("MOBILE_WEB")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("활성화 여부")])])]),_c('draggable',{attrs:{"tag":"tbody","move":_vm.updateBannerMoveEvent},on:{"sort":_vm.updatedBannerSortEvent},model:{value:(_vm.banners.edges),callback:function ($$v) {_vm.$set(_vm.banners, "edges", $$v)},expression:"banners.edges"}},_vm._l((_vm.banners.edges),function(item,index){return _c('tr',{key:item.node.id,class:_vm.getRowClass(item.node.status)},[_c('td',{attrs:{"scope":"row"}},[_c('strong',[_vm._v(_vm._s(index + 1))])]),_c('td',{attrs:{"scope":"row"}},[_vm._v(_vm._s(item.node.id))]),_c('td',[_c('router-link',{attrs:{"to":{
                name: 'BannerUpdate',
                params: { slotId: _vm.slotId, bannerId: item.node.id },
              }}},[_vm._v(" "+_vm._s(item.node.title)+" ")])],1),_c('td',[(item.node.contents.length || 0 > 0)?_c('b-img',{attrs:{"width":"200px","height":"80px","src":item.node.contents[0].content.thumbnail}}):_c('div',[_vm._v("미리보기 없음")])],1),_c('td',_vm._l((item.node.actionUris),function(actionUriElement){return _c('div',{key:actionUriElement.id},[_c('a',{attrs:{"href":_vm.actionUriToLink(actionUriElement.actionUri),"target":"_blank"}},[_vm._v(_vm._s(decodeURI(actionUriElement.actionUri)))]),_vm._l((actionUriElement.platforms),function(platform){return _c('span',{key:((actionUriElement.id) + "_" + platform)},[_vm._v("     "),_c('frip-badge',{attrs:{"type":_vm.platformToType(platform)}},[_vm._v(" "+_vm._s(platform)+" ")])],1)})],2)}),0),_c('td',[(item.node.beginShowingAt || item.node.endShowingAt)?_c('i',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.bottom",value:({
                title:
                  _vm.getLocalDateTime(("" + (item.node.beginShowingAt))) +
                  '~' +
                  _vm.getLocalDateTime(("" + (item.node.endShowingAt))),
              }),expression:"{\n                title:\n                  getLocalDateTime(`${item.node.beginShowingAt}`) +\n                  '~' +\n                  getLocalDateTime(`${item.node.endShowingAt}`),\n              }",modifiers:{"bottom":true}}],staticClass:"ni ni-time-alarm float-right lg",attrs:{"name":"ni ni-time-alarm","size":"lg"}}):_vm._e()]),_vm._l((_vm.shwoingPlatforms),function(platform){return _c('td',{key:((item.node.id) + "_" + platform)},[(_vm.showingLoading > 0)?_c('i',{staticClass:"fas fa-spinner fa-spin"}):(_vm.isShowing(("" + (item.node.id)), platform))?_c('frip-badge',{attrs:{"type":"danger","shape":"pill"}},[_vm._v("ON")]):_c('frip-badge',{attrs:{"type":"primary","shape":"pill"}},[_vm._v("OFF")])],1)}),_c('td',[_c('base-switch',{attrs:{"shape":"pill","type":"frip-primary","on-text":"","off-text":""},on:{"input":function($event){return _vm.updateStatus($event, ("" + index))}},model:{value:(item.node.status),callback:function ($$v) {_vm.$set(item.node, "status", $$v)},expression:"item.node.status"}})],1)],2)}),0),(_vm.banners.edges.length === 0)?_c('td',{attrs:{"colspan":"11"}},[_c('b-container',{staticClass:"text-center"},[_vm._v("등록된 배너가 없음")])],1):_vm._e()],1),_c('b-alert',{attrs:{"show":_vm.showErrorMessageTime,"dismissible":"","variant":"frip-danger"},on:{"dismissed":function($event){_vm.showErrorMessageTime = 0},"dismiss-count-down":_vm.countDownChanged}},[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }