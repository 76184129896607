








































































import Vue from 'vue';
import { Table, TableColumn } from 'element-ui';
import { ApolloError, ApolloQueryResult } from 'apollo-client';
import { SLOT_LIST_QUERY } from '../queries/query';
import { CommonState, Connection } from '@frientrip/domain';
import Spinner from '@/components/Spinner.vue';
import { errorHandler, ErrorLevel } from '@/common/errorHandler';
import { Slot, SlotEdge } from '../model/slot';

interface BannerSlot {
  id: string;
  name: string;
  maximumCountOfBanner: number;
  status: CommonState;
}

export default Vue.extend({
  name: 'BannerSlotList',
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    Spinner,
  },
  data() {
    return {
      slotList: { edges: [] as SlotEdge[] },
      totalCount: 0,
      loading: 0,
    };
  },
  computed: {
    slots(): Slot[] {
      return this.slotList.edges.map((edge: SlotEdge) => edge.node);
    },
  },
  apollo: {
    slotList: {
      query: SLOT_LIST_QUERY,
      error(e: ApolloError): void {
        console.error(`BannerSlotLIstError: `, e);
        errorHandler(e.message, ErrorLevel.NOTIFICATION, this);
      },
      result(
        result: ApolloQueryResult<{
          banner: { slots: Connection<BannerSlot> };
        }>
      ): void {
        this.totalCount = result.data.banner.slots.totalCount;
      },
      update: data => data.banner.slots,
    },
  },
});
