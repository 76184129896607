import swal from 'sweetalert2';
import Vue from 'vue';

export const enum ErrorLevel {
  NOTIFICATION = 'NOTIFICATION',
  ALERT = 'ALERT',
}

export const errorHandler = (
  errMessage: string,
  errorLevel: ErrorLevel,
  vue: Vue
) => {
  switch (errorLevel) {
    case ErrorLevel.NOTIFICATION:
      vue.$notify({
        title: '에러',
        message: errMessage,
        type: 'warning',
      });
      break;
    case ErrorLevel.ALERT:
      swal.fire(`에러`, errMessage, 'error');
      break;
  }
};
