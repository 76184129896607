


























































































import Vue, { VueConstructor } from 'vue';
import ImageInfoForm from '@/components/Forms/ImageInfoForm.vue';
import { BANNER_SLOT_QUERY } from '../queries/query';
import { ApolloError, ApolloQueryResult } from 'apollo-client';
import { apolloClient } from '@/apolloClient';
import { SlotService } from '../service/SlotService';
import { Slot, ImageInfo } from '../model/slot';
import Spinner from '@/components/Spinner.vue';
import InlineInput from '@/components/Forms/InlineInput.vue';

const slotService = new SlotService(apolloClient);

interface BannerSlotRequestParam {
  id: string;
}

export default (
  Vue as VueConstructor<Vue & { $refs: { [key: string]: HTMLElement } }>
).extend({
  name: 'BannerSlotForm',
  components: {
    ImageInfoForm,
    Spinner,
    InlineInput,
  },
  data() {
    return {
      slotId: this.$route.params.slotId || '0',
      loading: 0,
      form: {
        id: '',
        name: '',
        maximumCountOfBanner: 0,
        categoryId: '',
      },
      imageInfos: [] as ImageInfo[],
      defaultImageInfos: this.$route.params.slotId ? null : ([] as ImageInfo[]),
      dirty: {
        id: false,
        name: false,
        maximumCountOfBanner: false,
      },
      showErrorMessageTime: 0,
      errorMessage: '',
    };
  },
  computed: {
    isUpdateSlotForm(): boolean {
      if (this.slotId) return true;
      return false;
    },
    slotFormTitle(): string {
      if (this.slotId != '0') return '슬롯 수정';
      return '슬롯 등록';
    },
  },

  methods: {
    isCategoryId(): boolean {
      let result = true;
      if (this.slotId === '0') {
        result = false;
      }
      return result;
    },
    getMessageOfFormValidationStatus(): string {
      if (this.imageInfos.length === 0) return '이미지 정보는 필수입니다.';
      if (this.slotId !== '0' && this.slotId !== this.form.id) {
        return '아이디는 수정할 수 없습니다.';
      }
      return 'success';
    },
    showErrorMessage(message: string): void {
      this.showErrorMessageTime = 5;
      this.errorMessage = message.replace('GraphQL error:', '');
    },
    countDownChanged(showErrorMessageTime: number): void {
      this.showErrorMessageTime = showErrorMessageTime;
    },
    clickSubmitButton(): void {
      this.$refs.slotSubmit?.click();
    },
    async submitSlot(): Promise<void> {
      console.log('submit slot');
      const message = this.getMessageOfFormValidationStatus();
      if (message !== 'success') {
        this.showErrorMessage(message);
      } else {
        console.log('슬롯 등록');
        try {
          const imageInfos = this.imageInfos.map((imageInfo: ImageInfo) => {
            return {
              id: imageInfo.id,
              width: imageInfo.width,
              height: imageInfo.height,
              maximumFileSize: imageInfo.maximumFileSize,
              platforms: imageInfo.platforms,
            };
          });
          const slot = {
            ...this.form,
            infos: imageInfos,
          };

          await slotService.saveBannerSlot(slot, this.slotId === '0');
          this.$router.push({ name: 'BannerSlotList' });
        } catch (error) {
          console.error(error);
          this.showErrorMessage(error.message);
        }
      }
    },
    async deleteSlot() {
      if (confirm('정말로 삭제하시겠습니까?')) {
        try {
          await slotService.deleteBannerSlot(this.slotId);
          this.$router.push({ name: 'BannerSlotList' });
        } catch (error) {
          console.error(error);
          this.showErrorMessage(error.message);
        }
      }
    },
  },
  apollo: {
    slot: {
      query: BANNER_SLOT_QUERY,
      variables(): BannerSlotRequestParam {
        return {
          id: this.slotId || '',
        };
      },
      error(error: ApolloError): void {
        console.error(error);
      },
      result(result: ApolloQueryResult<{ banner: { slot: Slot } }>): void {
        const slot = result.data?.banner.slot;
        if (slot) {
          this.form.id = slot.id;
          this.form.name = slot.name;
          this.form.maximumCountOfBanner = slot.maximumCountOfBanner;
          this.form.categoryId = slot.categoryId || '';
          this.imageInfos = slot.infos;
          this.defaultImageInfos = slot.infos;
        }
      },
      update: data => data.slot,
      skip(): boolean {
        return this.slotId === '0';
      },
    },
  },
});
