<template>
  <div role="tablist" class="accordion">
    <b-card no-body class="mb-1">
      <b-card-body
        header-tag="header"
        href="#"
        role="tab"
        v-b-toggle="'history-' + toggleName"
        style="text-align: center"
      >
        <h5 class="mb-0 collapse-arrow">
          {{ qnsHistories.length }}개의 수정 이력
          <span class="ml-2 when-closed"
            ><i class="fas fa-chevron-down"></i
          ></span>
          <span class="ml-2 when-open"><i class="fas fa-chevron-up"></i></span>
        </h5>
      </b-card-body>
      <b-collapse
        :id="'history-' + toggleName"
        role="tabpanel"
        :accordion="toggleName"
      >
        <b-card-body>
          <b-list-group v-if="qnsHistories.length > 0">
            <div v-for="(qnaHistory, index) in qnsHistories" :key="index">
              <hr />
              <b-row class="my-2 p-3">
                <b-col class="col-lg-2">
                  <div class="pl-3">문의 내용</div>
                </b-col>
                <b-col>
                  {{ qnaHistory.content }}
                </b-col>
              </b-row>
              <hr />
            </div>
          </b-list-group>
        </b-card-body>
      </b-collapse>
    </b-card>
  </div>
</template>

<script>
export default {
  name: 'ProductQnaHistoryList',
  props: {
    qnsHistories: {
      type: Array,
      default: () => [],
    },
    toggleName: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped lang="scss">
.collapsed > .collapse-arrow > .when-open,
.not-collapsed > .collapse-arrow > .when-closed {
  display: none;
}
</style>
