import ApolloClient from 'apollo-client';
import { NormalizedCacheObject } from 'apollo-cache-inmemory';
import { SAVE_SEARCHKEYWORDS_MUTATION } from '../queries/mutation';
import { SearchKeywordParam } from '../model/searchKeyword';

export class SearchKeywordService {
  private readonly apollo: ApolloClient<NormalizedCacheObject>;
  constructor(apollo: ApolloClient<NormalizedCacheObject>) {
    this.apollo = apollo;
  }
  async saveSearchKeywords(params: SearchKeywordParam[]): Promise<void> {
    this.validate(params);
    await this.apollo.mutate({
      mutation: SAVE_SEARCHKEYWORDS_MUTATION,
      variables: {
        params: params,
      },
    });
  }
  validate(params: SearchKeywordParam[]): void {
    this.validateSeqNo(params);
    this.validateExposeTextAndText(params);
  }

  private validateExposeTextAndText(params: SearchKeywordParam[]): void {
    params.forEach(({ text, exposureText }) => {
      if (text === '' || exposureText === '') {
        throw new Error('홈 키워드와 검색어를 모두 입력해 주세요.');
      }
    });
  }

  private validateSeqNo(params: SearchKeywordParam[]): void {
    const seqNos = params.map(({ seqNo }) => {
      return seqNo;
    });
    seqNos.sort((a, b) => a - b);
    // check 1 ~ params.length + 1
    for (const [idx, seqNo] of seqNos.entries()) {
      if (idx + 1 != seqNo) {
        throw new Error('seqNo는 1부터 N까지 여야합니다.');
      }
    }
  }
}
