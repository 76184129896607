import ApolloClient from 'apollo-client';
import { NormalizedCacheObject } from 'apollo-cache-inmemory';
import {
  MerchandiserFilter,
  ProductMerchandiserConnection,
} from '@/domain/qna/model/merchandiser';
import { FIND_MERCHANDISER } from '@/domain/qna/queries/query';

export class MerchandiserService {
  private readonly apollo: ApolloClient<NormalizedCacheObject>;

  constructor(apollo: ApolloClient<NormalizedCacheObject>) {
    this.apollo = apollo;
  }

  async getMerchandiser(
    filter: MerchandiserFilter
  ): Promise<ProductMerchandiserConnection> {
    const response = await this.apollo.query({
      query: FIND_MERCHANDISER,
      variables: { filter },
    });

    return response.data.product.merchandisers;
  }
}
