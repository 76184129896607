


























































import Vue from 'vue';
import {
  EmailUserByLogin,
  SocialUserByLogin,
} from '@/domain/login/model/identity';

export default Vue.extend({
  props: {
    emailUser: EmailUserByLogin,
    socialUser: SocialUserByLogin,
  },
  computed: {
    isNewBie(): boolean {
      return this.emailUser?.isNewbie || this.socialUser?.isNewbie;
    },
  },
  methods: {
    async login() {
      if (this.emailUser) {
        await this.$store.dispatch('login', {
          email: this.emailUser.email,
          password: this.emailUser.password,
        });
      } else {
        await this.$store.dispatch('socialLogin', {
          token: this.socialUser.token,
          provider: this.socialUser.provider,
        });
      }
      this.$router.push('/').then(() => location.reload());
    },
  },
});
