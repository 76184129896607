import ApolloClient from 'apollo-client';
import { NormalizedCacheObject } from 'apollo-cache-inmemory';
import { UserConnection, UserFilter } from '@/domain/qna/model/user';
import { FIND_USER } from '@/domain/qna/queries/query';

export class UserService {
  private readonly apollo: ApolloClient<NormalizedCacheObject>;

  constructor(apollo: ApolloClient<NormalizedCacheObject>) {
    this.apollo = apollo;
  }

  async getUser(filter: UserFilter): Promise<UserConnection> {
    const response = await this.apollo.query({
      query: FIND_USER,
      variables: { filter },
    });

    return response.data.users;
  }
}
