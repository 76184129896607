import gql from 'graphql-tag';

export const FRAGMENT_HOST = gql`
  fragment hostFields on Host {
    id
    description
    name
    phoneNumber
    personalInfo {
      name
      phoneNumber
      email
    }
    memo
    super
    concerned
    status
    managerName
    user {
      id
    }
    createdAt
    updatedAt
    profileImage {
      thumbnail
      id
      uri
    }
    certificated
  }
`;
//query

export const getHost = gql`
  query getHost($id: ID!, $at: Instant) {
    host(id: $id) {
      ...hostFields
      approved
      approvedAt
      expiredAt
      approver {
        nickname
      }
      status
      grade(at: $at)
      introducer {
        typeId
        ... on HostIntroducerByEtc {
          __typename
          etc
        }
        ... on HostIntroducerByHost {
          __typename
          refereeName
          refereePhoneNumber
        }
        ... on HostIntroducerDefault {
          __typename
          typeId
        }
        ... on HostIntroducerBySaleManager {
          __typename
          saleManagerName
        }
      }
    }
  }

  ${FRAGMENT_HOST}
`;

export const getHostWithCommission = gql`
  query getHostWithCommission($id: ID!, $at: Instant) {
    host(id: $id) {
      ...hostFields
      approved
      approvedAt
      expiredAt
      approver {
        nickname
      }
      commission {
        absent {
          bias
          type
          charge
        }
        default {
          bias
          type
          charge
        }
      }
      status
      grade(at: $at)
      introducer {
        typeId
        ... on HostIntroducerByEtc {
          __typename
          etc
        }
        ... on HostIntroducerByHost {
          __typename
          refereeName
          refereePhoneNumber
        }
        ... on HostIntroducerDefault {
          __typename
          typeId
        }
        ... on HostIntroducerBySaleManager {
          __typename
          saleManagerName
        }
      }
    }
  }

  ${FRAGMENT_HOST}
`;

export const getHostsByFilter = gql`
  query getHostsByFilter(
    $page: Int
    $size: Int
    $filter: HostFilter
    $at: Instant
  ) {
    hosts(page: $page, size: $size, filter: $filter) {
      totalCount
      pageInfo {
        hasNextPage
      }
      edges {
        node {
          productInfo {
            counts {
              count
              label
              status
            }
          }
          id
          name
          personalInfo {
            name
            phoneNumber
            email
          }
          status
          super
          grade(at: $at)
          concerned
          managerName
          user {
            id
          }
          createdAt
          updatedAt
          profileImage {
            id
            uri
          }
        }
      }
    }
  }
`;

export const getHostIntroducerTypes = gql`
  query getHostIntroducerTypes {
    hostIntroducerTypes {
      id
      name
      status
    }
  }
`;

export const getHostByUserId = gql`
  query getHostByUserId($userId: ID!) {
    hostByUserId(userId: $userId) {
      ...hostFields
    }
  }

  ${FRAGMENT_HOST}
`;

//mutation

export const updateHost = gql`
  mutation updateHost($hostId: ID!, $param: HostBaseParam!) {
    updateHostBaseInfo(hostId: $hostId, param: $param) {
      ...hostFields
    }
  }

  ${FRAGMENT_HOST}
`;
export const updateHostMemo = gql`
  mutation updateHostMemo($hostId: ID!, $memo: String!) {
    updateHostMemo(hostId: $hostId, memo: $memo) {
      id
      memo
    }
  }
`;

export const updateHostIntroducer = gql`
  mutation updateHostIntroducer($param: HostIntroducerUpdateParam!) {
    updateHostIntroducer(param: $param) {
      ...hostFields
    }
  }

  ${FRAGMENT_HOST}
`;

export const updateHostProfile = gql`
  mutation updateHostProfile($hostId: ID!, $param: HostBaseParam!) {
    updateHostBaseInfo(hostId: $hostId, param: $param) {
      id
      description
      name
      phoneNumber
      personalInfo {
        name
        phoneNumber
        email
      }
      super
      managerName
      user {
        id
      }
      createdAt
      updatedAt
      profileImage {
        id
        uri
      }
    }
  }
`;

export const registHost = gql`
  mutation registHost($param: HostParam!) {
    registHost(param: $param) {
      ...hostFields
    }
  }

  ${FRAGMENT_HOST}
`;

export const signUpHost = gql`
  mutation signUpHost($param: HostParam!) {
    registHost(param: $param) {
      id
      name
    }
  }
`;

export const changeHostOwner = gql`
  mutation changeHostOwner($hostId: ID!, $userId: ID!) {
    changeHostOwner(hostId: $hostId, userId: $userId) {
      ...hostFields
    }
  }

  ${FRAGMENT_HOST}
`;
export const changeHostPersonalPhoneNumber = gql`
  mutation changeHostPersonalPhoneNumber($hostId: ID!, $phoneNumber: String!) {
    changeHostPersonalPhoneNumber(hostId: $hostId, phoneNumber: $phoneNumber) {
      ...hostFields
    }
  }

  ${FRAGMENT_HOST}
`;

export const withdrawHost = gql`
  mutation withdrawHost($hostId: ID!) {
    withdrawHost(hostId: $hostId) {
      ...hostFields
    }
  }

  ${FRAGMENT_HOST}
`;

export const reAuthenticatePhoneNumber = gql`
  mutation reAuthenticatePhoneNumber(
    $hostId: ID!
    $param: HostAuthenticationParam!
    $updatePersonalInfo: Boolean!
  ) {
    reAuthenticatePhoneNumber(
      hostId: $hostId
      param: $param
      updatePersonalInfo: $updatePersonalInfo
    ) {
      ...hostFields
    }
  }

  ${FRAGMENT_HOST}
`;

export const updateHostExtraInfo = gql`
  mutation updateHostExtraInfo($hostId: ID!, $param: HostExtraParam!) {
    updateHostExtraInfo(hostId: $hostId, param: $param) {
      ...hostFields
    }
  }

  ${FRAGMENT_HOST}
`;

export const approveHost = gql`
  mutation approveHost(
    $hostId: ID!
    $approvedAt: Instant!
    $expiredAt: Instant
  ) {
    approveHost(
      hostId: $hostId
      approvedAt: $approvedAt
      expiredAt: $expiredAt
    ) {
      ...hostFields
      approved
      approvedAt
      expiredAt
      approver {
        nickname
      }
    }
  }

  ${FRAGMENT_HOST}
`;

export const addHostConcern = gql`
  mutation addHostConcern($hostId: ID!, $param: HostConcernParam!) {
    addHostConcern(hostId: $hostId, param: $param) {
      id
    }
  }
`;

export const resolveHostConcern = gql`
  mutation resolveHostConcern(
    $concernId: ID!
    $param: HostConcernResolveParam!
  ) {
    resolveHostConcern(concernId: $concernId, param: $param) {
      id
    }
  }
`;
export const getHostConcerns = gql`
  query getHostConcerns($page: Int, $size: Int, $hostId: ID!) {
    hostConcerns(page: $page, size: $size, hostId: $hostId) {
      totalCount
      edges {
        node {
          id
          reason
          resolveInfo {
            reason
            worker {
              nickname
            }
          }
          worker {
            nickname
          }
        }
      }
    }
  }
`;

export const getHostGradePlans = gql`
  query getHostGradePlans($page: Int, $size: Int, $hostId: ID!) {
    hostGradePlans(page: $page, size: $size, hostId: $hostId) {
      totalCount
      edges {
        node {
          id
          grade
          startedAt
          endedAt
          worker {
            nickname
          }
        }
      }
    }
  }
`;

export const addHostGradePlan = gql`
  mutation addHostGradePlan($hostId: ID!, $param: HostGradeParam!) {
    addHostGradePlan(hostId: $hostId, param: $param) {
      id
      grade
      startedAt
      endedAt
      worker {
        nickname
      }
    }
  }
`;

export const updateHostGradePlan = gql`
  mutation updateHostGradePlan($gradePlanId: ID!, $param: HostGradeParam!) {
    updateHostGradePlan(gradePlanId: $gradePlanId, param: $param) {
      id
      grade
      startedAt
      endedAt
      worker {
        nickname
      }
    }
  }
`;

export const removeHostGradePlan = gql`
  mutation removeHostGradePlan($gradePlanId: ID!) {
    removeHostGradePlan(gradePlanId: $gradePlanId) {
      success
      message
    }
  }
`;

export const changeHostProfileImage = gql`
  mutation changeHostProfileImage($hostId: ID!, $contentId: ID!) {
    changeHostProfileImage(hostId: $hostId, contentId: $contentId) {
      id
    }
  }
`;

export const certificateHost = gql`
  mutation certificateHost($hostId: ID!) {
    certificateHost(hostId: $hostId) {
      id
    }
  }
`;

export const decertificateHost = gql`
  mutation decertificateHost($hostId: ID!) {
    decertificateHost(hostId: $hostId) {
      id
    }
  }
`;

export const getHostProfile = gql`
  query getHostProfile {
    me {
      id
      host {
        id
        description
        name
        phoneNumber
        personalInfo {
          name
          phoneNumber
          email
        }
        super

        managerName
        user {
          id
        }
        createdAt
        updatedAt
        profileImage {
          id
          uri
        }
      }
    }
  }
`;
