var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('content-card',[_c('div',[_c('span',{staticClass:"total-search"},[_vm._v("검색 결과 : "+_vm._s(_vm.totalCount.toLocaleString())+" 건")]),_c('el-table',{ref:"table",staticClass:"table-responsive table-flush align-items-center mt-2 table-",attrs:{"header-row-class-name":"thead-light","border":"","data":_vm.qnas,"empty-text":"문의가 없습니다.","cell-style":_vm.cellStyle,"header-cell-style":{ textAlign: 'center' }}},[_c('el-table-column',{staticStyle:{"float":"left"},attrs:{"label":"상태","prop":"type","min-width":"50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('frip-badge',{class:'qna-badge ' + _vm.badgeType(row.status)},[_vm._v(_vm._s(_vm.typeName(row.status)))])]}}])}),_c('el-table-column',{attrs:{"label":"비밀글","prop":"secret","width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.secret)?_c('div',{staticClass:"float-left align-bottom",staticStyle:{"width":"30px","height":"70px","display":"flex","justify-content":"center","align-items":"center"}},[_c('img',{attrs:{"src":require("@/assets/icons/Lock.svg"),"alt":"","width":"48"}})]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"label":"문의 내용","prop":"question","min-width":"200","header-align":"left","class-name":"sub_row text-left"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"float-right",staticStyle:{"width":"90%"}},[_c('el-tooltip',{staticStyle:{"white-space":"nowrap","text-overflow":"ellipsis","display":"block","overflow":"hidden"},attrs:{"disabled":_vm.disabledTooltip(row.product.title),"placement":"top-start","content":row.product.title}},[_c('b-link',{staticClass:"p-2 product-title",attrs:{"to":("/qna/detail/" + (row.id))}},[_vm._v(_vm._s(row.product.title))])],1),_c('div',{staticClass:"border-top w-100 middle-line"}),_c('el-tooltip',{staticStyle:{"white-space":"nowrap","text-overflow":"ellipsis","display":"block","overflow":"hidden"},attrs:{"placement":"top-start"}},[_c('div',{attrs:{"slot":"content"},slot:"content"},[_vm._v(" No. "+_vm._s(row.id)),_c('br'),_vm._v(" 문의 내용 : "+_vm._s(row.question)+" "),_c('br'),_vm._v(" 답변 내용 : "+_vm._s(row.answer.answer)+" ")]),_c('b-link',{staticClass:"p-2 question-content",attrs:{"to":("/qna/detail/" + (row.id))}},[_vm._v(_vm._s(row.question))])],1)],1)]}}])}),_c('el-table-column',{attrs:{"label":"문의한 크루","prop":"user"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(row.user.nickname))]}}])}),(_vm.checkAuthority)?_c('el-table-column',{attrs:{"label":"호스트","prop":"host"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(row.product.host.name))]}}],null,false,2560676563)}):_vm._e(),_c('el-table-column',{attrs:{"label":"최종 문의일","prop":"updatedAt"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.$moment(row.updatedAt).format('YYYY-MM-DD HH:mm')))]}}])}),_c('el-table-column',{attrs:{"label":"최종 답변일","prop":"updatedAt"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.answer.updatedAt !== '' ? _vm.$moment(row.answer.updatedAt).format('YYYY-MM-DD HH:mm') : ''))]}}])}),(_vm.checkAuthority)?_c('el-table-column',{attrs:{"label":"담당자","prop":"manager"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(row.product.manager ? row.product.manager.name : ''))]}}],null,false,421270839)}):_vm._e(),_c('el-table-column',{attrs:{"label":"더보기","min-width":50},nativeOn:{"click":function($event){$event.stopPropagation();}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-dropdown',{staticClass:"dropdown",attrs:{"trigger":"click"}},[_c('span',{staticClass:"btn btn-sm btn-icon-only text-light",staticStyle:{"cursor":"pointer"}},[_c('i',{staticClass:"fas fa-ellipsis-v mt-2"})]),_c('el-dropdown-menu',{staticClass:"dropdown-menu dropdown-menu-arrow show",attrs:{"slot":"dropdown"},slot:"dropdown"},[(_vm.checkAuthority)?_c('b-dropdown-item',{on:{"click":function($event){return _vm.hostDetail(row)}}},[_vm._v("호스트 관리")]):_vm._e(),(_vm.checkAuthority)?_c('b-dropdown-item',{on:{"click":function($event){return _vm.userDetail(row)}}},[_vm._v("크루 관리")]):_vm._e(),_c('b-dropdown-item',{on:{"click":function($event){return _vm.productDetail(row)}}},[_vm._v("상품관리")]),_c('b-dropdown-item',{attrs:{"disabled":row.product.status !== 'SALE'},on:{"click":function($event){return _vm.webProductDetail(row)}}},[_vm._v("상품상세 바로가기")])],1)],1)]}}])})],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }