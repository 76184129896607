






























































































































































import Vue from 'vue';
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  Table,
  TableColumn,
} from 'element-ui';
import { ProductQna, ProductQnaState } from '@/domain/qna/model/productQna';
import { getDomainName, isHostAdmin } from '@/env';
import { ProductState } from '@/domain/qna/model/product';

const LIMIT_WORD_LENGTH = 30;
export default Vue.extend({
  name: 'ProductQnaContainer',
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
  },
  props: {
    qnas: {
      type: Array,
      default: () => [],
    },
    totalCount: {
      type: Number,
      default: () => 0,
    },
  },
  computed: {
    checkAuthority(): boolean {
      return !isHostAdmin();
    },
  },
  methods: {
    typeName(type: string) {
      switch (type) {
        case ProductQnaState.NEW:
          return '신규문의';
        case ProductQnaState.CONFIRM:
          return '확인필요';
        case ProductQnaState.COMPLETE:
          return '답변완료';
        case ProductQnaState.BLIND:
          return '블라인드';
      }
    },
    badgeType(type: string) {
      switch (type) {
        case ProductQnaState.CONFIRM:
          return 'qna-confirm-badge';
        case ProductQnaState.COMPLETE:
          return 'qna-complete-badge';
        case ProductQnaState.BLIND:
          return 'qna-blind-badge';
        default:
          return 'qna-new-badge';
      }
    },
    hostDetail(row: ProductQna): void {
      //error alert 추가
      if (!row.product) {
        this.$modal.show({
          title: '호스트 관리 상세 조회 실패!',
          message: '호스트 관리를 볼 수 없습니다!',
          type: 'error',
        });

        return;
      }

      const hostId = row.product.host.id;
      (this as any).$router.push(`/host/list/${hostId}`);
    },
    productDetail(row: ProductQna): void {
      const productId = row.product.id;
      const productStatus = row.product.status;
      if (productStatus === ProductState.SALE) {
        (this as any).$router.push(`/product/list/${productId}`);
      }
    },
    webProductDetail(row: ProductQna): void {
      const productId = row.product.id;
      const productStatus = row.product.status;
      const domainUrl = getDomainName();
      if (productStatus === ProductState.SALE) {
        window.open(domainUrl + `/products/${productId}`);
      }
    },
    cellStyle({
      row,
      column,
      rowIndex,
      columnIndex,
    }: {
      row: number;
      column: number;
      rowIndex: number;
      columnIndex: number;
    }): void | { padding: string } {
      if (columnIndex === 2) {
        return { padding: '0' };
      }
    },
    disabledTooltip(word: string): boolean {
      return LIMIT_WORD_LENGTH > word.length;
    },
    userDetail(row: ProductQna): void {
      window.open(this.$wlink.user(row.user.id.toString()));
    },
  },
});
