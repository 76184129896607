import ApolloClient from 'apollo-client';
import { NormalizedCacheObject } from 'apollo-cache-inmemory';
import {
  ProductAnswer,
  ProductAnswerParam,
} from '@/domain/qna/model/productQna';
import {
  DELETE_ANSWER,
  DELETE_QUESTION,
  INACTIVATE_ANSWER,
  INACTIVATE_QUESTION,
  REGISTER_ANSWER,
  UPDATE_ANSWER,
} from '@/domain/qna/queries/mutation';

export class ProductQnaService {
  private readonly apollo: ApolloClient<NormalizedCacheObject>;

  constructor(apollo: ApolloClient<NormalizedCacheObject>) {
    this.apollo = apollo;
  }

  async createAnswer(answer: ProductAnswerParam): Promise<ProductAnswer> {
    const response = await this.apollo.mutate({
      mutation: REGISTER_ANSWER,
      variables: { param: answer },
    });

    return response.data.registerProductAnswer;
  }

  async updateAnswer(
    answer: ProductAnswerParam
  ): Promise<ProductAnswer | null> {
    const response = await this.apollo.mutate({
      mutation: UPDATE_ANSWER,
      variables: { param: answer },
    });

    return response.data.modifyProductAnswer;
  }

  async deleteAnswer(id: number): Promise<boolean> {
    const response = await this.apollo.mutate({
      mutation: DELETE_ANSWER,
      variables: { id: id },
    });

    return response.data.deleteAnswer;
  }

  async deleteQuestion(id: number): Promise<boolean> {
    const response = await this.apollo.mutate({
      mutation: DELETE_QUESTION,
      variables: { id: id },
    });

    return response.data.deleteQuestion;
  }

  async inactivateAnswer(id: number): Promise<boolean> {
    const response = await this.apollo.mutate({
      mutation: INACTIVATE_ANSWER,
      variables: { id },
    });

    return response.data.blindProductAnswer;
  }

  async inactivateQuestion(id: number): Promise<boolean> {
    const response = await this.apollo.mutate({
      mutation: INACTIVATE_QUESTION,
      variables: { id },
    });

    return response.data.blindProductQuestion;
  }
}
