

























import Vue, { VueConstructor } from 'vue';
import axios from 'axios';
import { SocialLinkProviderType, User } from '@/domain/user/models/user';
import { HostLoginService } from '@/domain/login/host/service/HostLoginService';
import { apolloClient } from '@/apolloClient';
import { SocialUserRegistParam } from '@/domain/login/model/identity';

export default (
  Vue as VueConstructor<Vue & { $refs: { [key: string]: HTMLElement } }>
).extend({
  props: {
    isSignUpButton: Boolean,
    provider: String,
  },
  // mounted() {
  //   this.loginService = new HostLoginService(apolloClient, this);
  // },
  data(): {
    loginService: HostLoginService;
    modalTitle: string;
    modalContent: string;
    loginModalStatus: boolean;
    socialLoginFailed: boolean;
  } {
    return {
      loginService: new HostLoginService(apolloClient, this),
      modalTitle: '',
      modalContent: '',
      loginModalStatus: false,
      socialLoginFailed: false,
    };
  },
  computed: {
    authStatus() {
      return this.$store.state.authStatus;
    },
  },
  methods: {
    showModal(title: string, content: string) {
      this.modalTitle = title;
      this.modalContent = content;
      this.loginModalStatus = true;
    },
    // //USERONLY인지 NONE인지에 대한 구분 필요
    // socialLogin(provider: SocialLinkProviderType, token: string) {
    //   this.$store
    //     .dispatch('socialLogin', {
    //       provider,
    //       token,
    //     })
    //     .then(() => this.$router.push('/').then(() => location.reload()))
    //     .catch((err: any) => {
    //       console.log(err);
    //       console.log(provider, token);
    //       this.$emit('fail', { provider, token });
    //       this.$router.push({
    //         name: 'registForm',
    //         params: { provider, token },
    //       });
    //     });
    // },
    async socialLogin(provider: SocialLinkProviderType, token: string) {
      const result = await this.loginService.socialLogin(provider, token);
      console.log(result);
      if (result.success) {
        this.$router.push('/').then(() => location.reload());
        return;
      }

      if (result.user) {
        console.log('here');
        const user = result.user;
        const param: SocialUserRegistParam = {
          provider,
          token,
          isNewbie: false,
          phoneNumber: user.mobileNumber,
          userId: user.id,
          email: user.email,
          agreement: { marketing: true },
        };

        this.$router.push({
          name: 'registForm',
          params: { param: JSON.stringify(param) },
        });
      } else {
        console.log('afds');
        console.log(result);
        if (provider === SocialLinkProviderType.KAKAO_SYNC) {
          if (
            !result.failData?.validEmail &&
            !result.failData?.validMobileNumber
          ) {
            this.$modal.show({
              title: '안내',
              message: '이미 가입된 이메일입니다.',
              showCancelButton: true,
            });
            throw Error('이미 가입된 이메일입니다.');
            //이미 가입된 이메일입니다
          } else if (!result.failData?.validMobileNumber) {
            this.$modal.show({
              title: '안내',
              message: '이미 가입된 번호입니다.',
              showCancelButton: true,
            });
            throw Error('이미 가입된 번호입니다.');
            //이미 가입된 번호입니다
          } else if (!result.failData?.validEmail) {
            this.$modal.show({
              title: '안내',
              message: '이미 가입된 이메일입니다.',
              showCancelButton: true,
            });
            throw Error('이미 가입된 이메일입니다.');
          }
        } else if (provider === SocialLinkProviderType.FACEBOOK) {
          if (!result.failData?.validEmail && result.failData?.email !== '') {
            this.$modal.show({
              title: '안내',
              message: '이미 가입된 이메일입니다.',
              showCancelButton: true,
            });
            throw Error('이미 가입된 이메일입니다.');
          }
        }
        const param: SocialUserRegistParam = {
          provider,
          token,
          isNewbie: true,
          phoneNumber: result.failData?.mobileNumber,
          email: result.failData?.email,
          nickname: result.failData?.nickname,
          agreement: { marketing: result.failData?.marketing || false },
        };
        console.log(param);
        this.$router.push({
          name: 'registForm',
          params: { param: JSON.stringify(param) },
        });
      }
    },
    loginWithKakao() {
      const self = this;
      // this.$modal.show({
      //   title: 'sdfg',
      //   message: 'fads',
      //   type: 'danger',
      // });

      this.$kakao.Auth.login({
        async success(authObj: any) {
          console.log(JSON.stringify(authObj));
          try {
            const result = await self.socialLogin(
              SocialLinkProviderType.KAKAO_SYNC,
              authObj.access_token
            );
          } catch (err: any) {
            self.$emit('error', err.message);
            self.$modal.show({
              title: '',
              message: err.message,
              type: 'primary',
            });
          }
        },
        fail(err: any) {
          alert(JSON.stringify(err));
        },
      });
    },
    loginWithFacebook() {
      console.log(this.$facebook);
      const self = this;
      this.$facebook.login(
        (response: any) => {
          console.log(response);
          try {
            self.socialLogin(
              SocialLinkProviderType.FACEBOOK,
              response.authResponse.accessToken
            );
          } catch (err: any) {
            self.$modal.show({
              title: '',
              message: err.message,
              type: 'primary',
            });
            self.$emit('error', err.message);
          }
        },
        { scope: 'email' }
      );
    },
  },
});
