






















import Vue, { VueConstructor } from 'vue';
import SocialLoginButton from '@/domain/login/common/views/SocialLoginButton.vue';
import AwsKinesis from '@/lib/awsKinesis';
import RegisterViewEvent from '@/lib/awsKinesis/events/RegisterViewEvent';
import RegisterTypeClickEvent from '@/lib/awsKinesis/events/RegisterTypeClickEvent';

export default (
  Vue as VueConstructor<Vue & { $refs: { [key: string]: HTMLElement } }>
).extend({
  components: { SocialLoginButton },
  data() {
    return {
      model: {
        email: '',
        password: '',
        rememberMe: false,
      },
      modalTitle: '',
      modalContent: '',
      loginModalStatus: false,
    };
  },
  computed: {
    authStatus() {
      return this.$store.state.authStatus;
    },
  },
  mounted() {
    new AwsKinesis().pushRecord(new RegisterViewEvent());
  },
  methods: {
    onClickEmailRegister() {
      new AwsKinesis().pushRecord(
        new RegisterTypeClickEvent('register', 'email')
      );
    },
    onSubmit() {
      console.log(this.model);
      // this will be called only after form is valid. You can do api call here to login
    },
    pressEnterKey() {
      // if (!this.validateAccountName()) return;
      this.$refs.password.focus();
    },
    showModal(title: string, content: string) {
      this.modalTitle = title;
      this.modalContent = content;
      this.loginModalStatus = true;
    },
  },
});
