import { gql } from 'apollo-boost';

export const REGISTER_ANSWER = gql`
  mutation registerProductAnswer($param: AnswerParam!) {
    registerProductAnswer(param: $param) {
      host {
        id
        name
      }
      answer
      createdAt
      updatedAt
    }
  }
`;

export const UPDATE_ANSWER = gql`
  mutation modifyProductAnswer($param: AnswerParam!) {
    modifyProductAnswer(param: $param) {
      host {
        id
        name
      }
      answer
      createdAt
      updatedAt
    }
  }
`;

export const DELETE_ANSWER = gql`
  mutation deleteProductAnswer($id: ID!) {
    deleteProductAnswer(id: $id) {
      message
      success
    }
  }
`;

export const DELETE_QUESTION = gql`
  mutation deleteProductQna($id: ID!) {
    deleteProductQna(id: $id) {
      message
      success
    }
  }
`;

export const REGISTER_SERVICE_ANSWER = gql`
  mutation registerServiceAnswer($param: ServiceAnswerParam!) {
    registerServiceAnswer(param: $param) {
      answer
      createdAt
    }
  }
`;

export const MODIFY_SERVICE_ANSWER = gql`
  mutation modifyServiceAnswer($param: ServiceAnswerParam!) {
    modifyServiceAnswer(param: $param) {
      answer
      createdAt
      updatedAt
    }
  }
`;

export const DELETE_SERVICE_ANSWER = gql`
  mutation deleteServiceAnswer($id: ID!) {
    deleteServiceAnswer(id: $id) {
      message
      success
    }
  }
`;

export const DELETE_SERVICE_QUESTION = gql`
  mutation deleteServiceQuestion($id: ID!) {
    deleteServiceQuestion(id: $id) {
      message
      success
    }
  }
`;

export const INACTIVATE_ANSWER = gql`
  mutation blindProductAnswer($id: ID!) {
    blindProductAnswer(id: $id) {
      message
      success
    }
  }
`;

export const INACTIVATE_QUESTION = gql`
  mutation blindProductQna($id: ID!) {
    blindProductQna(id: $id) {
      message
      success
    }
  }
`;
