































import Vue from 'vue';
import ProductQnaContainer from '@/domain/qna/component/ProductQnaContainer.vue';
import ProductQnaSearchFilter from '@/domain/qna/component/ProductQnaSearchFilter.vue';
import { HOST_QNA_LIST, QNA_LIST_QUERY } from '@/domain/qna/queries/query';
import { ApolloError, ApolloQueryResult } from 'apollo-client';
import {
  ProductQna,
  ProductQnaState,
  ProductQnaConnection,
  ProductQnaEdge,
  ProductQnaFilter,
} from '@/domain/qna/model/productQna';
import { isHostAdmin } from '@/env';
import Spinner from '@/components/Spinner.vue';

export default Vue.extend({
  name: 'ProductQnaList',
  components: {
    Spinner,
    ProductQnaContainer,
    ProductQnaSearchFilter,
  },
  data(): {
    totalCount: number;
    filter: ProductQnaFilter;
    qnas: ProductQna[];
    loading: number;
    showModal: boolean;
    page: string | number | (string | null)[];
    size: string | number | (string | null)[];
    authorityLevel: string;
    confirmedProductQna: ProductQna[];
    confirmedProductQnaTotalCount: number;
    hostId: string;
  } {
    return {
      totalCount: 0,
      filter: {
        isAnswering: true,
        statusIn: [
          ProductQnaState.NEW,
          ProductQnaState.BLIND,
          ProductQnaState.CONFIRM,
          ProductQnaState.COMPLETE,
        ],
        hostId: isHostAdmin() ? this.$store.state.userToken.hostId || 0 : null,
      },
      qnas: [],
      loading: 1,
      showModal: false,
      page: this.$route.query.page || 1,
      size: this.$route.query.size || 20,
      authorityLevel: isHostAdmin() ? 'HOST' : 'ADMIN',
      confirmedProductQna: [],
      confirmedProductQnaTotalCount: 0,
      hostId: this.$store.state.userToken.hostId || 0,
    };
  },
  deactivated() {
    console.log('deactive');
    this.$destroy();
  },
  beforeDestroy() {
    console.log('before destory');
  },
  mounted() {
    if (this.$route.query.hostId) {
      this.$data.filter.hostIds = [this.$route.query.hostId];
    }

    if (this.$route.query.userId) {
      this.$data.filter.userId = this.$route.query.userId;
    }

    if (this.$route.query.productId) {
      this.$data.filter.productIds = [this.$route.query.productId];
    }

    this.$apollo.queries.productQnas.refetch();
  },
  methods: {
    addFilter(filter: ProductQnaFilter): void {
      if (isHostAdmin()) {
        filter.hostIds = [this.$data.hostId];
      }

      this.$data.filter = filter;

      this.$apollo.queries.productQnas.refetch();
    },
    pageClick(bvEvent: any, page: number): void {
      this.$router.push(`/qna/list?page=${page}`);
    },
  },
  apollo: {
    productQnas: {
      query: QNA_LIST_QUERY,
      variables() {
        return {
          filter: this.$data.filter,
          page: Number(this.$data.page),
          size: Number(this.$data.size),
        };
      },
      error(e: ApolloError) {
        console.error(e);
      },
      update: data => data.qna.productQnas,
      result(
        result: ApolloQueryResult<{
          qna: { productQnas: ProductQnaConnection };
        }>
      ) {
        this.$data.totalCount = result.data.qna.productQnas.totalCount;
        this.$data.qnas = result.data.qna.productQnas.edges.map(
          (edge: ProductQnaEdge) => {
            if (!edge.node.answer) {
              edge.node.answer = {
                answer: '',
                createdAt: '',
                updatedAt: '',
              };
            }

            return edge.node;
          }
        );
        this.$data.loading = 0;
      },
    },
    productQnasForHost: {
      query: HOST_QNA_LIST,
      variables() {
        return {
          filter: {
            isAnswering: true,
            statusIn: [ProductQnaState.NEW, ProductQnaState.CONFIRM],
            hostIds: [this.$data.hostId],
          },
          page: Number(this.$data.page),
          size: Number(this.$data.size),
        };
      },
      error(e: ApolloError) {
        console.error(e);
      },
      result(
        result: ApolloQueryResult<{
          qna: { productQnas: ProductQnaConnection };
        }>
      ) {
        this.$data.confirmedProductQnaTotalCount =
          result.data.qna.productQnas.totalCount;
        this.$data.confirmedproductQna = result.data.qna.productQnas.edges.map(
          (edge: ProductQnaEdge) => {
            if (!edge.node.answer) {
              edge.node.answer = {
                answer: '',
                createdAt: '',
                updatedAt: '',
              };
            }

            return edge.node;
          }
        );
      },
      update: data => data.qna.productQnas,
      skip(): boolean {
        return !isHostAdmin();
      },
    },
  },
});
