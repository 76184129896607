import { CommonState } from '@frientrip/domain';
import ApolloClient from 'apollo-client';
import { NormalizedCacheObject } from 'apollo-cache-inmemory';
import Vue from 'vue';
import { User } from '@/domain/user/models/user';
import { GET_MY_PROFILE } from '@/domain/user/graphqls/user';

interface LoginResult {
  success: boolean;
  message?: string;
  user?: User;
  failData?: FailData;
}

interface FailData {
  email: string;
  marketing: boolean;
  mobileNumber: string;
  nickname: string;
  validEmail: boolean;
  validMobileNumber: boolean;
  validNickname: boolean;
}

export class HostLoginService {
  private readonly apollo: ApolloClient<NormalizedCacheObject>;
  private readonly vue: Vue;
  constructor(apollo: ApolloClient<NormalizedCacheObject>, vue: Vue) {
    console.log(Vue);
    this.apollo = apollo;
    this.vue = vue;
  }
  async emailLogin(email: string, password: string): Promise<LoginResult> {
    try {
      //호스트 권한이 있는지 확인.
      //호스트 권한이 있다면 바로 메인 페이지로 이동
      await this.vue.$store.dispatch('login', { email, password });
      return { success: true };
    } catch (err) {
      // console.log(err.response);
      // console.log(self);
      // console.error('login:', err);
      //유저 계정만 있을 때
      if (err.response.status === 401) {
        //유저 계정으로 로그인-> 유저 권한의 토큰 로컬 스토리지에 추가
        await this.vue.$store.dispatch('login', {
          email,
          password,
          byUser: true,
        });
        //유저 정보 가져옴
        const result = await this.apollo.query({
          query: GET_MY_PROFILE,
        });
        const user: User = result.data.me.user;
        return { success: false, user };
      } else {
        //유저 계정이 없다면 다른 프로세스로
        console.log(err.response);
        return { success: false, failData: err.response };
      }
    }
  }
  async socialLogin(provider: string, token: string): Promise<LoginResult> {
    try {
      //호스트 권한이 있는지 확인.
      //호스트 권한이 있다면 바로 메인 페이지로 이동
      await this.vue.$store.dispatch('socialLogin', { provider, token });
      return { success: true };
    } catch (err) {
      // console.log(err.response);
      // console.log(self);
      // console.error('login:', err);
      //유저 계정만 있을 때
      if (err.response.status === 401) {
        console.log('onlyUser');
        //유저 계정으로 로그인-> 유저 권한의 토큰 로컬 스토리지에 추가
        await this.vue.$store.dispatch('socialLogin', {
          token,
          provider,
          byUser: true,
        });
        //유저 정보 가져옴
        const result = await this.apollo.query({
          query: GET_MY_PROFILE,
        });
        const user: User = result.data.me.user;
        return { success: false, user };
      } else {
        console.log('none');
        console.log(err.response);
        //유저 계정이 없다면 다른 프로세스로
        return { success: false, failData: err.response.data.data };
      }
    }
  }
}
