var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"alertContainer"},[_c('frip-alert',{attrs:{"show":_vm.newbie && _vm.emailChecked ? _vm.alertShowTime : 0,"type":"primary","dismissible":true},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('img',{attrs:{"src":require("@/assets/icons/check-white.svg"),"width":"20"}})]},proxy:true}])},[_c('span',{staticClass:"subTitleText",style:({ margin: '0 6px 0 6px' })},[_vm._v(" 이메일 ")]),_vm._v("사용가능한 이메일 입니다! ")]),_c('frip-alert',{attrs:{"show":!_vm.newbie && _vm.emailChecked ? _vm.alertShowTime : 0,"type":"primary","dismissible":true},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('img',{attrs:{"src":require("@/assets/icons/check-white.svg"),"width":"20"}})]},proxy:true}])},[_c('span',{staticClass:"subTitleText",style:({ margin: '0 6px 0 6px' })},[_vm._v(" 이메일 ")]),_vm._v("이미 프립 회원이십니다! 프립 계정 비밀번호를 입력해주세요. ")]),_c('frip-alert',{attrs:{"show":!_vm.isValidPassword ? _vm.alertShowTime : false,"type":"warning","dismissible":true},on:{"dismissed":function () { return (_vm.isValidPassword = true); }},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('img',{attrs:{"src":require("@/assets/icons/warning-white.svg"),"width":"20"}})]},proxy:true}])},[_c('span',{staticClass:"subTitleText",style:({ margin: '0 6px 0 6px' })},[_vm._v(" 비밀번호 ")]),_vm._v("프립 계정 비밀번호를 입력해 주세요. ")])],1),_c('div',{staticClass:"titleText"},[_vm._v("계정 정보")]),_c('div',{staticClass:"subTitleText",style:({ marginTop: '32px' })},[_vm._v("이메일")]),_c('div',{style:({
      display: 'flex',
      position: 'relative',
      marginTop: '18px',
    })},[_c('div',{staticClass:"emailInput"},[_c('base-input',{attrs:{"rules":{ required: true, email: true },"invalid-message":"올바른 이메일 형식이 아닙니다.","placeholder":"이메일을 입력해주세요.","type":"email","height":"100%"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})],1),_c('frip-button',{staticClass:"inputSubmitButton",attrs:{"type":"secondary","disabled":!_vm.validateEmail(_vm.email),"loading":_vm.loading.checkEmail},on:{"click":_vm.checkEmail}},[_vm._v(_vm._s(_vm.emailChecked ? '이메일 다시 입력' : '중복 확인'))])],1),(!_vm.emailChecked)?_c('div',[_c('div',{staticClass:"subText1",style:({ marginTop: '16px' })},[_vm._v(" 아이디로 사용할 이메일 주소를 입력해주세요. "),_c('br'),_vm._v("아직 프립 가입을 하지 않으셨다면, 프립 계정이 함께 생성됩니다. ")]),_c('div',{style:({
        marginTop: '64px',
      })},[_c('frip-button',{staticClass:"button",attrs:{"type":"primary","disabled":!_vm.emailChecked}},[_vm._v("다음")]),_c('frip-button',{staticClass:"button",attrs:{"type":"secondary"},on:{"click":_vm.moveToPreviousStep}},[_vm._v("이전")])],1)]):_vm._e(),(_vm.newbie && _vm.emailChecked)?_c('div',[_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var validate = ref.validate;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();validate().then(_vm.createAccount)}}},[_c('div',{staticClass:"subText1"},[_vm._v(" 계약서 및 프립 관련 안내사항 등을 메일로 전달드립니다. ")]),_c('div',{staticClass:"subTitleText",style:({ marginTop: '32px' })},[_vm._v(" 비밀번호 ")]),_c('div',{style:({
            width: '100%',
            marginTop: '16px',
          })},[_c('div',[_c('base-input',{attrs:{"type":"password","rules":"required|password","placeholder":"비밀번호 입력","invalid-message":"비밀번호는 대문자, 소문자, 숫자, 특수문자 중 2가지 이상의 조합으로 10자 이상이어야 합니다."},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1),_c('div',{staticClass:"my-2"},[_c('base-input',{attrs:{"type":"password","rules":{ required: true, passwordCheck: [_vm.password] },"placeholder":"비밀번호 다시 입력","invalid-message":"먼저 입력하신 비밀번호와 일치하지 않습니다."},model:{value:(_vm.passwordCheck),callback:function ($$v) {_vm.passwordCheck=$$v},expression:"passwordCheck"}})],1)]),_c('div',{staticClass:"subText1"},[_vm._v(" 6~20자 영문 대소문자, 숫자, 특수문자 중 2가지 이상 조합 ")]),_c('div',{style:({
            marginTop: '64px',
          })},[_c('frip-button',{staticClass:"button",attrs:{"type":"primary","native-type":"submit"}},[_vm._v("다음")]),_c('frip-button',{staticClass:"button",attrs:{"type":"secondary"},on:{"click":_vm.moveToPreviousStep}},[_vm._v("이전")])],1)])]}}],null,false,1013547220)})],1):_vm._e(),(!_vm.newbie && _vm.emailChecked)?_c('div',[_c('div',{staticClass:"importantText",style:({ color: '#0075EF' })},[_vm._v(" 해당 이메일 주소의 프립 계정으로 가입할 수 있습니다. "),_c('br'),_vm._v("프립 계정의 비밀번호를 입력해 주세요, ")]),_c('div',{staticClass:"subTitleText",style:({ marginTop: '32px' })},[_vm._v("비밀번호")]),_c('div',{style:({
        width: '100%',
        marginTop: '16px',
      })},[_c('base-input',{style:({
          width: '340px',
        }),attrs:{"type":"password","rules":"password","invalid-message":"대/소문자, 숫자, 특수문자 중 2가지 이상의 조합으로 10자 이상"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1),_c('div',[_c('router-link',{staticClass:"subText1",style:({ color: '#333333' }),attrs:{"to":"/resetPassword/request"}},[_vm._v("비밀번호 찾기")])],1),_c('div',{style:({
        marginTop: '64px',
      })},[_c('frip-button',{staticClass:"button",attrs:{"type":"primary","disabled":!_vm.password,"loading":_vm.loading.checkAccount},on:{"click":_vm.checkAccount}},[_vm._v("다음")]),_c('frip-button',{staticClass:"button",attrs:{"type":"secondary"},on:{"click":_vm.moveToPreviousStep}},[_vm._v("이전")])],1)]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }