import gql from 'graphql-tag';

export const ELEMENTS_SEARCHKEYWORDS_QUERY = gql`
  query ($count: Int, $all: Boolean) {
    elements {
      searchKeywords(count: $count, all: $all) {
        id
        exposureText
        text
        seqNo
      }
    }
  }
`;
