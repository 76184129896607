





























import Vue from 'vue';
import DropdownText from '../../common/views/DropdownText.vue';
import axios from 'axios';
import { getLegacyApiUrl } from '@/env';
import AwsKinesis from '@/lib/awsKinesis';
import RegisterFormConditionsCompleteEvent from '@/lib/awsKinesis/events/RegisterFormConditionsCompleteEvent';

export default Vue.extend({
  components: {
    DropdownText,
  },
  props: {
    isAlreadyUser: Boolean,
  },
  data() {
    return {
      terms: {},
    };
  },
  computed: {},
  async created() {
    const result = await axios({
      url: `${getLegacyApiUrl()}/StaticMessages/metadata?`,
      method: 'GET',
    });
    this.terms = result?.data;
    console.log(result);
  },
  methods: {
    updateStep() {
      new AwsKinesis().pushRecord(new RegisterFormConditionsCompleteEvent());
      this.$emit('update', 'next');
    },
  },
});
