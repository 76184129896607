






















































































































import Vue from 'vue';
import BannerImageUpload from '../components/BannerImageUpload.vue';
import LocalDateTimePicker from '@/components/Forms/LocalDateTimePicker.vue';
import BannerActionUris from '../components/BannerActionUriForm.vue';
import { BANNER_QUERY, BANNER_SLOT_QUERY } from '../queries/query';
import { BannerService } from '../service/BannerService';
// eslint-disable-next-line no-unused-vars
import { ApolloError, ApolloQueryResult } from 'apollo-client';
import { Banner, BannerContent, BannerActionUri } from '../model/banner';
import { Slot, ImageInfo } from '../model/slot';
import Spinner from '@/components/Spinner.vue';
import InlineInput from '@/components/Forms/InlineInput.vue';
import { getPlatformSelectOptions } from '@/common/platforms';
import { Select, Option } from 'element-ui';
import { ClientPlatform } from '@frientrip/domain';
import { apolloClient } from '@/apolloClient';

const bannerService = new BannerService(apolloClient);

interface Image {
  id?: number;
  imageInfoId: number;
  contentId: string;
  testType: string;
  url?: string;
}

interface ImageContentIdEvent {
  aImage: string;
  bImage: string;
}

interface BannerRequestParam {
  id: string;
}

export default Vue.extend({
  name: 'BannerForm',
  components: {
    BannerImageUpload,
    BannerActionUris,
    ReservationTime: LocalDateTimePicker,
    Spinner,
    InlineInput,
    [Select.name]: Select,
    [Option.name]: Option,
  },
  data() {
    return {
      slot: {} as Slot,
      defaultImages: [],
      bannerId: Number(this.$route.params.bannerId) || 0,
      slotId: this.$route.params.slotId,
      loading: false,
      imageDirty: false,
      actionUriDirty: false,
      form: {
        title: '',
        beginShowingAt: '',
        endShowingAt: '',
        description: '',
        actionUris: [
          {
            actionUri: '',
            platforms: [] as ClientPlatform[],
          },
        ] as BannerActionUri[],
        isBooked: 'manualSetting',
        buttonText: null as string | null,
      },
      images: [] as Image[],
      platformsOtions: getPlatformSelectOptions(),
      options: [
        { value: 'manualSetting', label: '수동 설정' },
        { value: 'autoSetting', label: '자동 설정' },
      ],
      showErrorMessageTime: 0,
      errorMessage: '',
    };
  },
  computed: {
    isCreateFormOrBannerImageExisted(): boolean {
      // 업데이트 화면인 경우 banner정보가 불러온 후 렌더링 되기 위해서 만듬
      if (this.bannerId == 0) return true;
      if (this.images.length) return true;
      if (this.imageDirty) return true;
      return false;
    },
    isCreateFormOrBannerActionUrisExisted(): boolean {
      // 업데이트 화면인 경우 banner actionUris정보가 불러온 후 렌더링 되기 위해서 만듬
      if (this.bannerId == 0) return true;
      if (this.form.actionUris[0].actionUri !== '') return true;
      if (this.actionUriDirty) return true;
      return false;
    },
  },
  methods: {
    changeActionUriDirty(): void {
      this.actionUriDirty = true;
    },
    findImageIndexByTestTypeAndInfoId(
      infoId: number,
      testType: string
    ): number {
      const index = this.images.findIndex(
        (image: Image) =>
          image.imageInfoId === infoId && image.testType === testType
      );
      return index;
    },
    updateImageList(
      conetentId: string,
      index: number,
      info: ImageInfo,
      testType: string
    ): void {
      const image = {
        imageInfoId: Number(info.id),
        url: '',
        contentId: conetentId,
        testType: testType,
      };
      if (index < 0 && image.contentId !== '') {
        this.addImage(image);
      } else if (
        image.contentId !== this.images[index]?.contentId &&
        image.contentId !== ''
      ) {
        this.changeImage(index, image);
      } else if (image.contentId === '' && index >= 0) {
        this.deleteImage(index);
      }
    },
    // TODO: 지금은 A/B 테스트에만 한정되어 있지만 좀 더 확장할 방법을 생각해야함
    getContentId(event: ImageContentIdEvent, info: ImageInfo): void {
      const aTypeImageIndex = this.findImageIndexByTestTypeAndInfoId(
        Number(info.id),
        'A'
      );
      this.updateImageList(event.aImage, aTypeImageIndex, info, 'A');

      const bTypeImageIndex = this.findImageIndexByTestTypeAndInfoId(
        Number(info.id),
        'B'
      );

      this.updateImageList(event.bImage, bTypeImageIndex, info, 'B');
    },

    // 이미지 추가
    addImage(newImage: Image): void {
      console.log('addImage!');
      this.images.push(newImage);
    },
    // 이미지 삭제
    deleteImage(index: number): void {
      // console.log('deleteImage!');
      this.images.splice(index, 1);
      this.imageDirty = true;
    },
    // 이미지 교체
    changeImage(index: number, newImage: Image): void {
      // console.log('changeImage!');
      this.images.splice(index, 1, newImage);
    },
    getImagesBySlotId(imageInfoId: number) {
      const images = this.images.filter(
        (image: Image) => image.imageInfoId === imageInfoId
      );
      return images;
    },
    showErrorMessage(message: string): void {
      this.showErrorMessageTime = 5;
      this.errorMessage = message.replace('GraphQL error:', '');
    },
    countDownChanged(showErrorMessageTime: number) {
      this.showErrorMessageTime = showErrorMessageTime;
    },
    // 수정 필요
    async submitBanner(): Promise<void> {
      try {
        this.loading = true;
        if (this.form.isBooked === 'manualSetting') {
          this.form.beginShowingAt = '';
          this.form.endShowingAt = '';
        }
        if (this.bannerId === 0) {
          const newBanner = await bannerService.createBanner(this.slot.id, {
            ...this.form,
            actionUris: this.form.actionUris,
            images: this.images,
          });
          // console.log(newBanner);
        } else {
          const updatedBanner = await bannerService.updateBanner(
            this.bannerId,
            this.slot.id,
            {
              ...this.form,
              actionUris: this.form.actionUris,
              images: this.images,
            }
          );
          // console.log(updatedBanner);
        }
        this.$router.push({
          name: 'BannerList',
          params: { slotId: this.slotId },
        });
      } catch (error) {
        console.error(error);
        this.showErrorMessage(error.message);
      } finally {
        this.loading = false;
      }
    },
    async deleteBanner(): Promise<void> {
      if (confirm('정말로 삭제하시겠습니까?')) {
        try {
          this.loading = true;
          await bannerService.deleteBanner(this.bannerId);
          this.$router.push({
            name: 'BannerList',
            params: { slotId: this.slotId },
          });
        } catch (error) {
          console.error(error);
          this.showErrorMessage(error.message);
        } finally {
          this.loading = false;
        }
      }
    },
  },

  apollo: {
    // 슬롯 정보를 가져옴
    slot: {
      query: BANNER_SLOT_QUERY,
      variables(): BannerRequestParam {
        return {
          id: this.slotId,
        };
      },
      error(e: ApolloError): void {
        console.error(e);
      },
      update: data => data.banner.slot,
    },
    // 배너 정보를 가져옴(수정 화면 일때)
    banner: {
      query: BANNER_QUERY,
      variables(): BannerRequestParam {
        return {
          id: this.bannerId.toString(),
        };
      },
      error(e: ApolloError): void {
        console.error(e);
      },
      result(result: ApolloQueryResult<{ banner: { banner: Banner } }>): void {
        if (result.data) {
          const banner = result.data.banner.banner;
          this.form.title = banner.title;
          this.form.beginShowingAt = banner.beginShowingAt;
          this.form.endShowingAt = banner.endShowingAt;
          this.form.description = banner.description;
          this.form.buttonText = banner.buttonText;
          this.form.actionUris = banner.actionUris.map(
            (bannerActionUri: BannerActionUri) => {
              return {
                id: bannerActionUri.id,
                actionUri: bannerActionUri.actionUri,
                platforms: bannerActionUri.platforms,
              };
            }
          );
          this.images = banner.contents.map((content: BannerContent) => {
            return {
              id: Number(content.id),
              imageInfoId: Number(content.imageInfo.id),
              url: content.content.uri,
              contentId: content.content.id,
              testType: content.testType,
            };
          });

          this.form.isBooked =
            this.form.beginShowingAt || this.form.endShowingAt
              ? 'autoSetting'
              : 'manualSetting';
        }
      },
      update: data => data.banner,
      skip(): boolean {
        return this.bannerId === 0;
      },
    },
  },
});
