import ApolloClient from 'apollo-client';
import { NormalizedCacheObject } from 'apollo-cache-inmemory';
import {
  ServiceAnswer,
  ServiceAnswerParam,
} from '@/domain/qna/model/serviceQna';
import {
  DELETE_SERVICE_ANSWER,
  DELETE_SERVICE_QUESTION,
  MODIFY_SERVICE_ANSWER,
  REGISTER_SERVICE_ANSWER,
} from '@/domain/qna/queries/mutation';

export class ServiceQnaService {
  private readonly apollo: ApolloClient<NormalizedCacheObject>;

  constructor(apollo: ApolloClient<NormalizedCacheObject>) {
    this.apollo = apollo;
  }

  async registerAnswer(param: ServiceAnswerParam): Promise<ServiceAnswer> {
    const response = await this.apollo.mutate({
      mutation: REGISTER_SERVICE_ANSWER,
      variables: { param: param },
    });

    return response.data.registerServiceAnswer;
  }

  async updateAnswer(param: ServiceAnswerParam): Promise<ServiceAnswer> {
    const response = await this.apollo.mutate({
      mutation: MODIFY_SERVICE_ANSWER,
      variables: { param: param },
    });

    return response.data.modifyServiceAnswer;
  }

  async deleteAnswer(id: number): Promise<boolean> {
    const response = await this.apollo.mutate({
      mutation: DELETE_SERVICE_ANSWER,
      variables: { id: id },
    });

    return response.data.deleteServiceAnswer;
  }

  async deleteQuestion(id: number): Promise<boolean> {
    const response = await this.apollo.mutate({
      mutation: DELETE_SERVICE_QUESTION,
      variables: { id: id },
    });

    return response.data.deleteServiceQuestion;
  }
}
