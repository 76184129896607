






























import Vue from 'vue';

export default Vue.extend({
  name: 'local-date-time-picker',
  props: {
    label: String,
    value: String,
    defaultTime: String,
  },
  data() {
    return {
      selectedTime: '',
      utcTime: '',
    };
  },
  created() {
    console.log('value: ', this.value);
    if (this.value) {
      const inputValueDate = new Date(this.value);
      this.selectedTime = this.$moment(inputValueDate).format(
        'YYYY-MM-DDTHH:mm:ss'
      );
    }
    console.log('selectedTime: ', this.selectedTime);
    const nowDate = new Date();
    this.setUTCTime(nowDate);
  },
  methods: {
    initValue(): void {
      this.selectedTime = '';
      this.$emit('input', '');
    },
    getReservationTime(): void {
      if (this.selectedTime !== '') {
        const emitDate = new Date(this.selectedTime);
        this.setUTCTime(emitDate);
        this.$emit('input', emitDate.toISOString());
      }
    },
    setUTCTime(inputDate: Date): void {
      this.utcTime = 'UTC ' + this.$moment(inputDate).format('Z');
    },
  },
});
