import { gql } from 'apollo-boost';

export const SAVE_BANNERSLOT_MUTATION = gql`
  mutation saveBannerSlot($param: BannerSlotParam!, $isNewSlot: Boolean!) {
    saveBannerSlot(param: $param, isNewSlot: $isNewSlot) {
      id
      name
      maximumCountOfBanner
      createdAt
      updatedAt
      infos {
        id
        width
        height
        platforms
        maximumFileSize
        createdAt
        updatedAt
      }
    }
  }
`;

export const DELETE_BANNERSLOT_MUTATION = gql`
  mutation delteBannerSlot($id: ID!) {
    deleteBannerSlot(id: $id)
  }
`;

export const CREATE_BANNER_MUTATION = gql`
  mutation createBanner($param: BannerParam!) {
    createBanner(param: $param) {
      id
      actionUri
      indexNo
      title
      buttonText
      description
      beginShowingAt
      endShowingAt
      contents {
        id
        testType
        content {
          id
        }
        imageInfo {
          id
          width
          height
          platforms
          maximumFileSize
          createdAt
          updatedAt
          status
        }
      }
      createdAt
      updatedAt
      status
    }
  }
`;

export const UPDATE_BANNER_MUTATION = gql`
  mutation updateBanner($id: ID!, $param: BannerParam!) {
    updateBanner(id: $id, param: $param) {
      id
      actionUri
      indexNo
      title
      buttonText
      description
      beginShowingAt
      endShowingAt
      createdAt
      updatedAt
      status
      contents {
        id
        testType
        content {
          id
        }
        imageInfo {
          id
          width
          height
          platforms
          maximumFileSize
          createdAt
          updatedAt
          status
        }
      }
    }
  }
`;
export const UPDATE_BANNERS_INDEXNO_AND_STATUS_BY_SLOTID_MUTATION = gql`
  mutation updateBannersIndexNoAndStatusBySlotId(
    $slotId: String!
    $params: [BannerIndexNoAndStatus!]!
  ) {
    updateBannersIndexNoAndStatusBySlotId(slotId: $slotId, params: $params) {
      id
      actionUri
      indexNo
      title
      buttonText
      beginShowingAt
      endShowingAt
      status
      contents {
        id
        testType
        content {
          id
        }
        imageInfo {
          id
          width
          height
          platforms
          maximumFileSize
          createdAt
          updatedAt
          status
        }
      }
    }
  }
`;

export const DELETE_BANNER_MUTATION = gql`
  mutation deleteBanner($id: ID!) {
    deleteBanner(id: $id)
  }
`;

export const REFRESH_BANNERS_MUTATION = gql`
  mutation refreshBanners($slotId: String!) {
    refreshBanners(slotId: $slotId)
  }
`;
