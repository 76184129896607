


















































































































import Vue from 'vue';
import FripCircleText from '@/components/FripComponents/FripCircleText.vue';
import Term from '@/domain/login/host/views/Term.vue';
import HostRegistForm from '@/domain/login/host/views/HostRegistForm.vue';
import RegistForm from '@/domain/login/host/views/RegistForm.vue';
import {
  EmailUserRegistParam,
  SocialUserRegistParam,
  HostLoginState,
  EmailUserByLogin,
  SocialUserByLogin,
} from '@/domain/login/model/identity';

export default Vue.extend({
  components: {
    FripCircleText,
    Term,
    HostRegistForm,
    RegistForm,
  },
  props: {
    emailUserByLogin: EmailUserByLogin,
    socialUserByLogin: SocialUserByLogin,
  },
  data(): {
    step: number;
    socialToken: string;
    provider: string;
    emailUser: EmailUserRegistParam;
    socialUser: SocialUserRegistParam;
    loginState: string;
    isNewbie: boolean;
  } {
    return {
      loginState: this.$route.params.loginState || HostLoginState.NONE,
      step: 1,
      socialToken: this.$route.params.token || '',
      provider: this.$route.params.provider || '',
      emailUser: this.emailUserByLogin || null,
      socialUser: this.socialUserByLogin || null,
      isNewbie:
        this.socialUserByLogin?.isNewbie ||
        this.emailUserByLogin?.isNewbie ||
        (!this.socialUserByLogin && !this.emailUserByLogin),
    };
  },
  methods: {
    setEmailUserForm(param: EmailUserRegistParam) {
      this.emailUser = param;
    },
    // setSocialUserForm(param: SocialUserRegistParam) {
    //   this.emailUser = param;
    // },
    setStep(direction: string) {
      console.log(direction);
      if (direction === 'previous') {
        this.step--;
      } else {
        this.step++;
      }
    },
  },
});
