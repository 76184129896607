import ApolloClient from 'apollo-client';
import { NormalizedCacheObject } from 'apollo-cache-inmemory';
import { HostConnection, HostFilter } from '@/domain/qna/model/host';
import { FIND_HOSTS } from '@/domain/qna/queries/query';

export class HostService {
  private readonly apollo: ApolloClient<NormalizedCacheObject>;

  constructor(apollo: ApolloClient<NormalizedCacheObject>) {
    this.apollo = apollo;
  }

  async getHosts(filter: HostFilter): Promise<HostConnection> {
    const result = await this.apollo.query({
      query: FIND_HOSTS,
      variables: { filter },
    });

    return result.data.hosts;
  }
}
