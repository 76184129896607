import gql from 'graphql-tag';

export const SAVE_SEARCHKEYWORDS_MUTATION = gql`
  mutation ($params: [SearchKeywordParam!]!) {
    saveSearchKeywords(params: $params) {
      id
      exposureText
      text
      seqNo
    }
  }
`;
